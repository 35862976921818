import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector, shallowEqual} from "react-redux"

import {getProduct, getAllProducts} from "../../store/actions/product"
import Breadcrumb from "../../components/Breadcrumb"
import Detail from "./Detail"
import Specification from "./Specification"
import RelatedProducts from "./RelatedProducts"
import Share from "./Share"
import detail_bg from "../../assets/images/detail_bg.jpg"

const ProductDetail = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const {slug} = useParams()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getProduct(slug))
        dispatch(getAllProducts())
        setIsLoading(false)
    }, [slug, dispatch])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const {product, products} = useSelector(
        (state) => ({
            product: state.product.product,
            products: state.product.products,
        }),
        shallowEqual
    )

    let relatedProducs
    if (products && products.length > 0) {
        relatedProducs = products.filter((item) => {
            return ((item?.category?.name === product?.category?.name) && (item?.sku !== product.sku))
        })
    }

    if (isLoading) return <p>Loading...</p>
    return (
        <div className="innerpage-wrapper">
            <Breadcrumb title="Product Detail" backgroundImage={detail_bg}/>
            <Detail data={product}/>
            <Specification data={product}/>
            <RelatedProducts data={relatedProducs}/>
            <Share data={product}/>
        </div>
    )
}

export default ProductDetail
