import React from "react"

import Title from "../../components/Title"
import CategoryItem from "../../components/category"
import Button from "../../components/Button"

const Category = ({ data }) => {
    return (
        <>
            {data && data.length > 0 && (
                <section className="section category_section">
                    <div className="container">
                        <Title
                            title="Featured category"
                            className="text-center"
                            bgTitle="Featured category"
                        />
                        <div className="category_wrapper">
                            <CategoryItem data={data} />
                            <Button
                                type="link"
                                label="View All"
                                className="view_all"
                                linkTo="/products"
                            />
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export default Category
