import React, { useState, useEffect } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import ReactPaginate from "react-paginate"
import { toast } from "react-toastify"

import { getAllBanners, deleteBanner } from "../../../store/actions/banner"
import Table from "../../components/Table"
import Search from "../../components/Search"

const Banner = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllBanners())
        toast.success("Banner read successfull")
        setIsLoading(false)
    }, [dispatch])
    const { banners } = useSelector(
        (state) => ({
            banners: state.banner.banners,
        }),
        shallowEqual
    )
    const handleSearch = (searchTerm) => {
        dispatch(getAllBanners(searchTerm))
    }
    if (isLoading) {
        return <p>Loading....</p>
    }
    return (
        <>
            <div className="table_box_content">
                <div className="table_header">
                    <h3>Banner List</h3>
                    <Search
                        handleSearch={(searchTerm) => handleSearch(searchTerm)}
                    />
                </div>
                <Table
                    data={banners}
                    deleteItem={(id) => dispatch(deleteBanner(id))}
                    linkTo="banners"
                />
                <div className="table_footer">
                    <ReactPaginate />
                </div>
            </div>
        </>
    )
}

export default Banner
