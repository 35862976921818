import React, { useState, useEffect } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import ReactPaginate from "react-paginate"
import { toast } from "react-toastify"

import { getAllClients, deleteClient } from "../../../store/actions/client"
import Table from "../../components/Table"
import Search from "../../components/Search"

const Clients = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllClients())
        toast.success("Client read successfull")
        setIsLoading(false)
    }, [dispatch])
    const { clients } = useSelector(
        (state) => ({
            clients: state.client.clients,
        }),
        shallowEqual
    )
    const handleSearch = (searchTerm) => {
        dispatch(getAllClients(searchTerm))
    }
    console.log(clients)
    if (isLoading) {
        return <p>Loading....</p>
    }
    return (
        <>
            <div className="table_box_content">
                <div className="table_header">
                    <h3>Client List</h3>
                    <Search
                        handleSearch={(searchTerm) => handleSearch(searchTerm)}
                    />
                </div>
                <Table
                    data={clients}
                    deleteItem={(id) => dispatch(deleteClient(id))}
                    linkTo="client"
                />
                <div className="table_footer">
                    <ReactPaginate />
                </div>
            </div>
        </>
    )
}

export default Clients
