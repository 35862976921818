import React from "react"
import Slider from "react-slick"

const Clients = (props) => {
    const settings = {
        dots: true,
        infinite: props.items.length > 6 ? true : false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: props.items.length > 6 ? true : false,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
        ],
    }
    return (
        <Slider {...settings}>
            {props.items.map((item, index) => {
                return (
                    <div>
                        <div className="slider_item" key={`clients-${index}`}>
                            <figure>
                                <img
                                    src={item?.image?.url}
                                    alt={item?.image?.alt}
                                />
                            </figure>
                        </div>
                    </div>
                )
            })}
        </Slider>
    )
}

export default Clients
