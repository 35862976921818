import Api from "../API/axios"
import {
    ADD_CLIENT,
    GET_ALL_CLIENTS,
    GET_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    CLEAR_FIELDS,
} from "../constants/client"
import { toast } from "react-toastify"
import history from "../../shared/history"

export const addClient = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post("/clients", data)
            if (res?.data?.message === "success") {
                toast.success("Client added successfully")
                history.push("/admin/clients")
            }
            dispatch({ type: ADD_CLIENT, payload: res })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getAllClients = (searchTerm) => {
    const query = searchTerm ? searchTerm : ""
    return async (dispatch) => {
        try {
            const res = await Api.get(`/clients?search=${query}`)

            dispatch({ type: GET_ALL_CLIENTS, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getClient = (id) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/clients/${id}`)
            dispatch({ type: GET_CLIENT, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const updateClient = (id, data) => {
    return async (dispatch) => {
        try {
            const res = await Api.put(`/clients/${id}`, data)
            if (res?.data?.message === "success") {
                toast.success("Client updated successfully")
                history.push("/admin/clients")
            }
            dispatch({ type: UPDATE_CLIENT, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const deleteClient = (id) => {
    return async (dispatch) => {
        try {
            await Api.delete(`/clients/${id}`)
            dispatch({ type: DELETE_CLIENT, payload: id })
            toast.success("Client deleted successfully")
        } catch (err) {
            toast.error("Error deleting Client")
        }
    }
}

export const clearFields = (data) => {
    return {
        type: CLEAR_FIELDS,
        payload: data,
    }
}
