import React, { useEffect } from "react"

import { BiMap, BiPhoneCall, BiEnvelopeOpen } from "react-icons/bi"

const Detail = ({ data }) => {
    return (
        <div className="contact_info">
            <ul>
                <li>
                    <BiMap />
                    <h3>Address</h3>
                    <p>{data && data[0] && data[0].address}</p>
                </li>
                <li>
                    <BiPhoneCall />
                    <h3>Phone</h3>
                    <p>{data && data[0] && data[0].phone}</p>
                </li>
                <li>
                    <BiEnvelopeOpen />
                    <h3>Email</h3>
                    <p>{data && data[0] && data[0].email}</p>
                </li>
            </ul>
        </div>
    )
}

export default Detail
