import {
    ADD_SERVICE,
    GET_ALL_SERVICES,
    GET_SERVICE,
    DELETE_SERVICE,
    UPDATE_SERVICE,
    CLEAR_FIELDS,
} from "../constants/service"

const initialState = {
    services: [],
    publishedServices: [],
    service: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_SERVICE: {
            return {
                ...state,
                services: state.services.concat(action.payload.data),
            }
        }
        case GET_ALL_SERVICES: {
            return {
                ...state,
                services: action.payload.data,
                publishedServices: action.payload.data.filter((item) => {
                    return item.isPublish === true
                }),
            }
        }
        case GET_SERVICE: {
            return {
                service: action.payload.data,
            }
        }
        case UPDATE_SERVICE: {
            return {
                ...state,
                service: action.payload.data,
            }
        }
        case DELETE_SERVICE: {
            return {
                ...state,
                services: state.services.filter(
                    (item) => item.slug !== action.payload
                ),
            }
        }
        case CLEAR_FIELDS: {
            return {
                service: action.payload,
            }
        }
        default:
            return state
    }
}
