import React, { useState } from "react"
import { Map, TileLayer, Marker } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import L from "leaflet"

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

const Leaflet = () => {
    const [coordinate, setCoordinate] = useState({
        lat: 27.7170,
        lng: 85.2735,
    })
    const position = [coordinate.lat, coordinate.lng]

    console.log("position",position)
    return (
        <Map center={position} zoom={15}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}></Marker>
        </Map>
    )
}

export default Leaflet
