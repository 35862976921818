import React, {useEffect} from "react"
import {useParams} from "react-router-dom"
import {shallowEqual, useDispatch, useSelector} from "react-redux"
import {Form, Formik} from "formik"
import {toast} from "react-toastify"
import * as Yup from "yup"

import {AddCategory, clearFields, getCategory, updateCategory,} from "../../../store/actions/product"
import FormikControl from "../../components/FormikControl"

const Add_Category = () => {
    const radioOptions = [
        {key: "Yes", value: "yes"},
        {key: "No", value: "no"},
    ]

    const dispatch = useDispatch()
    const {slug} = useParams()
    const isAddMode = !slug
    useEffect(() => {
        if (slug) {
            dispatch(getCategory(slug))
            toast.success("category read successfull")
        } else {
            dispatch(clearFields((initialValues = {})))
        }
    }, [slug, dispatch])
    const {singleCategory} = useSelector(
        (state) => ({
            singleCategory: state.product.singleCategory,
        }),
        shallowEqual
    )
    let initialValues = {
        name: singleCategory.name ? singleCategory.name : "",
        image: null,
        isPublish:
            singleCategory.isPublish === true
                ? "yes"
                : singleCategory.isPublish === false
                ? "no"
                : "",
        display_order: singleCategory.display_order
            ? singleCategory.display_order
            : "",
        description: singleCategory.description
            ? singleCategory.description
            : "",
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        // image: Yup.mixed().required("Required"),
    })
    const onSubmit = (values) => {
        const {name, image, isPublish, display_order, description} = values
        const dataToSend = new FormData()
        dataToSend.append("name", name)
        console.log("image",image)
        if (image!=null) {
            dataToSend.append("image", image)
        }
        dataToSend.append("isPublish", isPublish)
        dataToSend.append("display_order", display_order)
        if (isAddMode) {
            dispatch(AddCategory(dataToSend))
        } else {
            dispatch(updateCategory(slug, dataToSend))
        }
    }
    return (
        <div className="form_wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form>
                        <h1>
                            {isAddMode
                                ? " Product Category Form"
                                : "Edit category"}
                        </h1>
                        <div className="form_inner_content">
                            <div className="row">
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Product Category Title"
                                        name="name"
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="radio"
                                        label="Publish"
                                        name="isPublish"
                                        options={radioOptions}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="Display Order"
                                        name="display_order"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="image"
                                        label="Product Category Image"
                                        name="image"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <button
                                        className="submit_btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Add_Category
