import React, { useState } from "react"

const Search = ({ handleSearch }) => {
    const [searchKey, setSearchKey] = useState("")
    return (
        <div className="table_search_wrapper">
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    handleSearch(searchKey)
                }}
            >
                <input
                    className="form-control"
                    placeholder="Search..."
                    name="searchTerm"
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                />
            </form>
        </div>
    )
}

export default Search
