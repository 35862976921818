import API from "../API/axios"
import {LOGIN} from "../constants/auth";
import {toast} from "react-toastify";
import history from "../../shared/history";

export const Login = (data) => {
    console.log(data)
    return async (dispatch) => {

        API.post("/auth/login", data).then((res) => {

            // if (res.status === 201) {
            //     console.log("res", res)
            //
            // }
            dispatch({type: LOGIN, payload: res.data})
            window.location.href = "/admin/dashboard";
            toast.success("Login successfully")
        }).catch((err) => {
            console.log(err)
            toast.error("Login failed")
        })

    }
}

export const Register = (data) => {
    return async (dispatch) => {
        const res = await API.post("auth/register", data)
        console.log(res)
    }
}

export const Logout = () => {
}
