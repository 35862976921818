import React from "react"
import MessengerCustomerChat from "react-messenger-customer-chat"

const Messenger = () => {
    return (
        <div className="page_messenger">
            <MessengerCustomerChat
                pageId="101801011894911"
                appId="231265145263156"
            />
        </div>
    )
}

export default Messenger
