import React, {useEffect, useState} from "react"
import {shallowEqual, useDispatch, useSelector} from "react-redux"

import {getAllCategory, getProductByCategory,} from "../../store/actions/product"
import Breadcrumb from "../../components/Breadcrumb"
import Products from "./Products"
import Sidebar from "./Sidebar"
import productBgImg from "../../assets/images/product_bg.png"

const ProductPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedPage, setSelectedPage] = useState("")
    const [selectedCagegoryId, setSelectedCategoryId] = useState("")
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllCategory())
        dispatch(getProductByCategory(selectedCagegoryId, selectedPage))
        setIsLoading(false)
    }, [dispatch, selectedCagegoryId, selectedPage])

    const {category, filteredProducts, count, page, pages} = useSelector(
        (state) => ({
            category: state.product.category,
            count: state.product.count,
            filteredProducts: state.product.filteredProducts,
            page: state.product.page,
            pages: state.product.pages,
        }),
        shallowEqual
    )
    const handleCategoryId = (id) => {
        setSelectedCategoryId(id)
    }
    const handlePageChange = (number) => {
        setSelectedPage(number)
    }
    const handleReset = () => {
        setSelectedCategoryId("")
        setSelectedPage("")
    }


    if (isLoading) return <p>Loading...</p>

    return (
        <div className="innerpage-content">
            <Breadcrumb title="All products" backgroundImage={productBgImg}/>
            <section className="section product_page_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4">
                            <Sidebar
                                data={category}
                                handleCategoryId={(id) => handleCategoryId(id)}
                                handleReset={() => handleReset()}
                            />
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <Products
                                data={filteredProducts}
                                count={count}
                                page={page}
                                pages={pages}
                                handlePageChange={(pageNumber) =>
                                    handlePageChange(pageNumber)
                                }
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ProductPage
