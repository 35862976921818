import Api from "../API/axios"
import { toast } from "react-toastify"
import {
    ADD_FAQ,
    GET_ALL_FAQ,
    UPDATE_FAQ,
    GET_SINGLE,
    DELETE_FAQ,
    CLEAR_FIELDS,
} from "../constants/faq"
import history from "../../shared/history"

export const addFaq = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post("/faq", data)
            if (res?.data?.message === "success") {
                toast.success("Faq added successfully")
                history.push("/admin/faq")
            }
            dispatch({ type: ADD_FAQ, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getAllFaq = (searchTerm) => {
    const query = searchTerm ? searchTerm : ""
    return async (dispatch) => {
        try {
            const res = await Api.get(`/faq?search=${query}`)
            dispatch({ type: GET_ALL_FAQ, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const getSingleFaq = (id) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/faq/${id}`)
            dispatch({ type: GET_SINGLE, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const updateFaq = (id, data) => {
    return async (dispatch) => {
        try {
            const res = await Api.put(`/faq/${id}`, data)
            if (res?.data?.message === "success") {
                toast.success("Faq updated successfully")
                history.push("/admin/faq")
            }
            dispatch({ type: UPDATE_FAQ, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const deleteFaq = (id) => {
    return async (dispatch) => {
        try {
            await Api.delete(`/faq/${id}`)
            dispatch({ type: DELETE_FAQ, payload: id })
            toast.success("Faq deleted successfully")
        } catch (err) {
            toast.error("Error deleting faq")
        }
    }
}

export const clearFields = (data) => {
    return {
        type: CLEAR_FIELDS,
        payload: data,
    }
}
