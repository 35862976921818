import React from "react"
import HelmetMetaData from "../../components/Helmet"
import { htmlToText } from "html-to-text"
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share"

const Share = ({ data }) => {
    const shareUrl = "http://kitchenmartnepal.mervixtech.com/"
    return (
        <div className="social_share_wrapper">
            <HelmetMetaData
                title={data?.name}
                image={data?.image?.url}
                description={data.description && htmlToText(data.description)}
            ></HelmetMetaData>
            <ul>
                <li>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={data.description && htmlToText(data.description)}
                        image={data?.image?.url}
                    >
                        <FacebookIcon size={40} />
                    </FacebookShareButton>
                </li>
                <li>
                    <TwitterShareButton
                        url={shareUrl}
                        url={shareUrl}
                        quote={data.description && htmlToText(data.description)}
                        image={data?.image?.url}
                    >
                        <TwitterIcon size={40} />
                    </TwitterShareButton>
                </li>
                {/* <li>
                    <WhatsappShareButton url={shareUrl} title={""}>
                        <WhatsappIcon size={40} />
                    </WhatsappShareButton>
                </li> */}
            </ul>
        </div>
    )
}

export default Share
