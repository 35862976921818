import React, {useEffect, useState} from "react"
import {shallowEqual, useDispatch, useSelector} from "react-redux"
import ReactPaginate from "react-paginate"
import {toast} from "react-toastify"

import {deleteProduct, getAllProducts, getProductsWithPagination} from "../../../store/actions/product"
import Table from "../../components/Table"
import Search from "../../components/Search"

const Product = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllProducts())
        toast.success("Product read successfull")
        setIsLoading(false)
    }, [dispatch])
    const {products} = useSelector(
        (state) => ({
            products: state.product.products,
        }),
        shallowEqual
    )
    const handleSearch = (searchTerm) => {
        console.log("Sss", searchTerm)
        dispatch(getAllProducts(searchTerm))
    }

    const handlePageChange = (selectedPage) => {
        console.log("Sss", selectedPage)
        const {selected} = selectedPage
        dispatch(getProductsWithPagination(selected))
    }


    if (isLoading) {
        return <p>Loading....</p>
    }
    return (
        <>
            <div className="table_box_content">
                <div className="table_header">
                    <h3>Product List</h3>
                    <Search
                        handleSearch={(searchTerm) => handleSearch(searchTerm)}
                    />
                </div>
                <Table
                    data={products}
                    deleteItem={(id) => dispatch(deleteProduct(id))}
                    linkTo="product"
                />
                <div className="table_footer">
                    <ReactPaginate
                        onPageChange={(selectedPage) => handlePageChange(selectedPage+1)}
                    />
                </div>
            </div>
        </>
    )
}

export default Product
