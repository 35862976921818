import React, { useState, useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"

import { getAllTestimonials } from "../../store/actions/testimonial"
import { getAllServices } from "../../store/actions/service"
import { getAllClients } from "../../store/actions/client"
import Breadcrumb from "../../components/Breadcrumb"
import Testimonials from "../../components/testimonials"
import Client from "../../components/clients"
import Info from "./Info"
import serviceBgImg from "../../assets/images/services_bg.jpg"

const ServicePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllTestimonials())
        dispatch(getAllClients())
        dispatch(getAllServices())
        setIsLoading(false)
    }, [dispatch])
    const {
        publishedServices,
        publishedtestimonials,
        publishedClients,
    } = useSelector(
        (state) => ({
            publishedServices: state.service.publishedServices,
            publishedtestimonials: state.testimonial.publishedtestimonials,
            publishedClients: state.client.publishedClients,
        }),
        shallowEqual
    )
    if (isLoading) return <p>Loading...</p>
    return (
        <div className="innerpage-content">
            <Breadcrumb title="Our services" backgroundImage={serviceBgImg} />
            <Info data={publishedServices} />
            <Testimonials data={publishedtestimonials} />
            <Client data={publishedClients} />
        </div>
    )
}

export default ServicePage
