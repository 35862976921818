import React from "react"
import { Link } from "react-router-dom"

const CategoryItem = ({ data }) => {
    return (
        <ul>
            {data &&
                data
                    .filter((item, idx) => idx < 5)
                    .map((item, index) => {
                        return (
                            <li key={`category-${index}`}>
                                <Link to="/">
                                    <figure>
                                        <img
                                            src={item?.image}
                                            alt="product category"
                                        />
                                        <h4>{item?.name}</h4>
                                    </figure>
                                </Link>
                            </li>
                        )
                    })}
        </ul>
    )
}

export default CategoryItem
