import React from "react"
import {Link} from "react-router-dom"

import Button from "../components/Button"
import no_image from "../assets/images/no_image.png";

const ProductItem = ({image, name, slug, relatedproducts}) => {
    const handlePageToTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <div
            className="product-item"
            onClick={relatedproducts ? handlePageToTop : undefined}
        >
            <Link to={`/product/${slug}`}>
                <figure>
                    {image && image.url ? (
                        <img src={image?.url} alt={image?.alt}/>
                    ) : (
                        <img src={image.url ? image.url : no_image} alt={image?.alt}
                        />
                    )}
                </figure>
                <div className="product-content">
                    <h5>{name}</h5>
                </div>
            </Link>
            <Button
                linkTo={`/product/${slug}`}
                label="View Detail"
                className="view_btn"
                type="link"
            />
        </div>
    )
}

export default ProductItem
