import React from "react"
import {Provider} from "react-redux"
import {Redirect, Route, Router, Switch} from "react-router-dom"
import {Slide, ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import store from "./store/store"
import AdminLayout from "./layout/Admin"
import PublicLayout from "./layout/Public"
import history from "./shared/history"
import Dashboard from "./admin/screens/Dashboard"
import UsersList from "./admin/screens/Users"
import UserForm from "./admin/screens/Users/Form"
import AddBanner from "./admin/screens/Banner/Form"
import BannerList from "./admin/screens/Banner"
import AboutForm from "./admin/screens/About/Form"
import AboutList from "./admin/screens/About"
import ClientForm from "./admin/screens/Clients/Form"
import ClientList from "./admin/screens/Clients"
import AddProductCategory from "./admin/screens/Product/Add_Category"
import CategoryList from "./admin/screens/Product/CategoryList"
import ProductList from "./admin/screens/Product"
import ProductForm from "./admin/screens/Product/Form"
import TestimonialList from "./admin/screens/Testimonial"
import TestimonialForm from "./admin/screens/Testimonial/Form"
import ServicesList from "./admin/screens/services"
import ServicesForm from "./admin/screens/services/Form"
import ContactList from "./admin/screens/Contact"
import Enquiry from "./admin/screens/Enquiry"
import FaqList from "./admin/screens/Faq"
import FaqForm from "./admin/screens/Faq/Form"
import PageSetting from "./admin/screens/setting/Form"
import Homepage from "./screens/Home"
import ProductDetail from "./screens/Detail"
import AboutPage from "./screens/About"
import ContactPage from "./screens/Contact"
import ServicePage from "./screens/service"
import ProductPage from "./screens/products"

import Login from "./shared/Login"
import RegisterPage from "./shared/Register"
import NotFound from "./shared/NotFound"

// import UserRoutes from "./routes/User";

// public routes

function App() {

    let isAuthenticated = localStorage.getItem("token") ? true : false
    return (
        <Provider store={store}>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar
                transition={Slide}
            />
            <Router history={history}>
                <Switch>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/register" exact component={RegisterPage}/>
                    <Route path="/admin/:path?/:query?" exact component={(props) =>
                        isAuthenticated === true ?
                            <AdminLayout>
                                <Switch>
                                    <Route
                                        exact
                                        path="/admin/dashboard"
                                        component={Dashboard}
                                    />
                                    <Route
                                        exact
                                        path="/admin/users"
                                        component={UsersList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/users/:id"
                                        component={UserForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/banner/add"
                                        component={AddBanner}
                                    />
                                    <Route
                                        exact
                                        path="/admin/banners/:slug"
                                        component={AddBanner}
                                    />
                                    <Route
                                        exact
                                        path="/admin/banners"
                                        component={BannerList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/about/add"
                                        component={AboutForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/about/:slug"
                                        component={AboutForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/about"
                                        component={AboutList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/product/add"
                                        component={ProductForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/product/:slug"
                                        component={ProductForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/products"
                                        component={ProductList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/testimonial/add"
                                        component={TestimonialForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/testimonials/:slug"
                                        component={TestimonialForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/testimonials"
                                        component={TestimonialList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/services/add"
                                        component={ServicesForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/services/:slug"
                                        component={ServicesForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/services"
                                        component={ServicesList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/contacts"
                                        component={ContactList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/client/add"
                                        component={ClientForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/client/:slug"
                                        component={ClientForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/clients"
                                        component={ClientList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/faq/add"
                                        component={FaqForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/faq/:slug"
                                        component={FaqForm}
                                    />
                                    <Route
                                        exact
                                        path="/admin/faq"
                                        component={FaqList}
                                    />
                                    {/* <Route
                                    exact
                                    path="/admin/services/add"
                                    component={ServicesForm}
                                /> */}
                                    <Route
                                        exact
                                        path="/admin/add_category"
                                        component={AddProductCategory}
                                    />
                                    <Route
                                        exact
                                        path="/admin/category/:slug"
                                        component={AddProductCategory}
                                    />
                                    <Route
                                        exact
                                        path="/admin/category_list"
                                        component={CategoryList}
                                    />
                                    <Route
                                        exact
                                        path="/admin/enquiry"
                                        component={Enquiry}
                                    />
                                    <Route
                                        exact
                                        path="/admin/setting"
                                        component={PageSetting}
                                    />
                                    <Route component={NotFound}/>
                                </Switch>
                            </AdminLayout> :
                            <Redirect
                                to={{
                                    pathname: "/login",
                                }}
                            />
                    }
                    />

                    <Route>
                        <PublicLayout>
                            <Switch>
                                <Route
                                    path="/product/:slug"
                                    exact
                                    component={ProductDetail}
                                />
                                <Route
                                    path="/about-us"
                                    exact
                                    component={AboutPage}
                                />
                                <Route
                                    path="/services"
                                    exact
                                    component={ServicePage}
                                />
                                <Route
                                    path="/products"
                                    exact
                                    component={ProductPage}
                                />
                                <Route
                                    path="/contact-us"
                                    exact
                                    component={ContactPage}
                                />
                                <Route path="/" exact component={Homepage}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </PublicLayout>
                    </Route>
                </Switch>
            </Router>
        </Provider>
    )
}

export default App
