import React, { useState, useEffect } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Title from "../../components/Title"
import Products from "../../components/Products"

const AllProducts = ({ category, handleCategoryId, filteredProducts }) => {
    const [categoryId, setCategoryId] = useState("")
    useEffect(() => {
        handleCategoryId(categoryId)
    }, [categoryId])
    return (

        <>
            {category && category.length > 0 && (
                <section className="section product-section bg-gray">
                    <div className="container">
                        <Title
                            title="Featured products"
                            className="text-center"
                            bgTitle="Product"
                        />
                        <div className="product_wrapper featured_tabs_product">
                            <Tabs>
                                <TabList>
                                    <Tab onClick={() => setCategoryId("")}>
                                        All
                                    </Tab>
                                    {category[0] && (
                                        <Tab
                                            onClick={() =>
                                                setCategoryId(category[0]?._id)
                                            }
                                        >
                                            {category[0]?.name}
                                        </Tab>
                                    )}
                                    {category[1] && (
                                        <Tab
                                            onClick={() =>
                                                setCategoryId(category[1]?._id)
                                            }
                                        >
                                            {category[1]?.name}
                                        </Tab>
                                    )}
                                    {category[2] && (
                                        <Tab
                                            onClick={() =>
                                                setCategoryId(category[2]?._id)
                                            }
                                        >
                                            {category[2]?.name}
                                        </Tab>
                                    )}
                                </TabList>
                                <TabPanel>
                                    <Products
                                        slider
                                        dots={true}
                                        arrows={false}
                                        autoPlay={true}
                                        items={filteredProducts}
                                    />
                                </TabPanel>
                                {category[0] && (
                                    <TabPanel>
                                        <Products
                                            slider
                                            dots={true}
                                            arrows={false}
                                            autoPlay={true}
                                            items={filteredProducts}
                                        />
                                    </TabPanel>
                                )}
                                {category[1] && (
                                    <TabPanel>
                                        <Products
                                            slider
                                            dots={true}
                                            arrows={false}
                                            autoPlay={true}
                                            items={filteredProducts}
                                        />
                                    </TabPanel>
                                )}
                                {category[2] && (
                                    <TabPanel>
                                        <Products
                                            slider
                                            dots={true}
                                            arrows={false}
                                            autoPlay={true}
                                            items={filteredProducts}
                                        />
                                    </TabPanel>
                                )}
                            </Tabs>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export default AllProducts
