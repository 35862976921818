import React from "react"

import BarChart from "./BarChart"
import List from "./List"

const Dashboard = () => {
    const data = [
        { count: 100, title: "total visits" },
        { count: 80, title: "This month visits" },
        { count: 30, title: "Today visits" },
        { count: 5, title: "Today unique visits" },
    ]
    return (
        <div className="dashboard_wrapper">
            <div className="list_item_wrapper">
                <ul>
                    {data &&
                        data.length > 0 &&
                        data.map((item, index) => {
                            return (
                                <li key={index}>
                                    <List
                                        count={item.count}
                                        title={item.title}
                                    />
                                </li>
                            )
                        })}
                </ul>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="dashboard_item">
                        <BarChart />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
