import {
    ADD_PRODUCT,
    ADD_CATEGORY,
    GET_ALL_CATEGORY,
    GET_ALL_PRODUCT,
    GET_CATEGORY,
    GET_PRODUCT,
    UPDATE_CATEGORY,
    UPDATE_PRODUCT,
    DELETE_CATEGORY,
    DELETE_PRODUCT,
    GET_FILTERED_PRODUCTS,
    CLEAR_FIELDS,
} from "../constants/product"

const initialState = {
    products: [],
    publishedproducts: [],
    featuredProducts: [],
    category: [],
    newArrival: [],
    publishedCategory: [],
    singleCategory: {},
    product: {},
    filteredProducts: [],
    filterFeaturedProdoucts: [],
    count: null,
    page: null,
    pages: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_CATEGORY: {
            return {
                ...state,
                category: state.category.concat(action.payload.data),
            }
        }
        case GET_ALL_CATEGORY: {
            return {
                ...state,
                category: action.payload.data,

                publishedCategory: action.payload.data.filter((item) => {
                    return item.isPublish === true
                }),
            }
        }
        case GET_CATEGORY: {
            return {
                ...state,
                singleCategory: action.payload.data,
            }
        }
        case UPDATE_CATEGORY: {
            return {
                ...state,
                singleCategory: action.payload.data,
            }
        }
        case DELETE_CATEGORY: {
            return {
                ...state,
                category: state.category.filter(
                    (item) => item.slug !== action.payload
                ),
            }
        }
        case ADD_PRODUCT: {
            return {
                ...state,
                products: state.products.concat(action.payload.data),
            }
        }
        case GET_ALL_PRODUCT: {
            return {
                ...state,
                products: action.payload.data,
                newArrival: action.payload.data && action.payload.data.filter((item) => {
                    return item.isPublish === true && item.isFeatured === false
                }),
                publishedproducts: action.payload.data && action.payload.data.filter((item) => {
                    return item.isPublish === true
                }),
                featuredProducts: action.payload.data && action.payload.data.filter((item) => {
                    return item.isFeatured === true
                }),
            }
        }
        case GET_PRODUCT: {
            return {
                ...state,
                product: action.payload.data,
            }
        }
        case UPDATE_PRODUCT: {
            return {
                ...state,
                product: action.payload.data,
            }
        }
        case DELETE_PRODUCT: {
            return {
                ...state,
                products: state.products.filter(
                    (item) => item.slug !== action.payload
                ),
            }
        }
        case GET_FILTERED_PRODUCTS: {
            return {
                ...state,
                count: action.payload.data?.results,
                pages: action.payload.data?.pages,
                page: action.payload?.data.page,
                filteredProducts: [...action.payload.data.data],
                filterFeaturedProdoucts: action.payload.data.data.filter(
                    (item) => {
                        return item.isFeatured === true
                    }
                ),
            }
        }
        case CLEAR_FIELDS: {
            return {
                singleCategory: action.payload,
                product: action.payload,
            }
        }
        default:
            return state
    }
}
