import React from "react"
import { Field, ErrorMessage } from "formik"
import TextError from "./TextError"

function RadioButtons(props) {
    const { label, name, options, required, ...rest } = props
    return (
        <div className="form-group radio_btn">
            <label>
                {label}
                {required && <span style={{ marginLeft: 3 }}>*</span>}
            </label>
            <div className="radio_options_wrapper">
                <Field name={name}>
                    {({ field }) => {
                        return options.map((option) => {
                            return (
                                <div key={option.key} className="radio-options">
                                    <input
                                        type="radio"
                                        id={option.value}
                                        {...field}
                                        {...rest}
                                        value={option.value}
                                        checked={field.value === option.value}
                                    />
                                    <label htmlFor={option.value}>
                                        {option.key}
                                    </label>
                                </div>
                            )
                        })
                    }}
                </Field>
            </div>
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default RadioButtons
