import React, { useState, useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"

import { getAllAbout } from "../../store/actions/about"
import { getAllTestimonials } from "../../store/actions/testimonial"
import { getAllClients } from "../../store/actions/client"
import Breadcrumb from "../../components/Breadcrumb"
import Testimonials from "../../components/testimonials"
import Client from "../../components/clients"
import Info from "./Info"
import aboutBgImg from "../../assets/images/about_bg.png"

const AboutPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllAbout())
        dispatch(getAllClients())
        dispatch(getAllTestimonials())
        setIsLoading(false)
    }, [dispatch])
    const {
        publishedAboutUs,
        publishedtestimonials,
        publishedClients,
    } = useSelector(
        (state) => ({
            publishedAboutUs: state.about.publishedAboutUs,
            publishedtestimonials: state.testimonial.publishedtestimonials,
            publishedClients: state.client.publishedClients,
        }),
        shallowEqual
    )
    if (isLoading) return <p>Loading...</p>

    return (
        <>
            <Breadcrumb title="About us" backgroundImage={aboutBgImg} />
            <Info data={publishedAboutUs} />
            <Testimonials data={publishedtestimonials} />
            <Client data={publishedClients} />
        </>
    )
}

export default AboutPage
