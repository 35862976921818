import {
    ADD_BANNER,
    GET_BANNER,
    GET_ALL_BANNERS,
    CLEAR_FIELDS,
    UPDATE_BANNER,
    DELETE_BANNER,
} from "../constants/banner"

const initialState = {
    banners: [],
    publishedBanners: [],
    banner: {},
    stauts: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_BANNER: {
            return {
                ...state,
                banners: state.banners.concat(action.payload.data.data),
                stauts: action.payload.message,
            }
        }
        case GET_ALL_BANNERS: {
            return {
                ...state,
                banners: action.payload.data,
                publishedBanners: action.payload.data.filter(
                    (item) => item.isPublish === true
                ),
            }
        }
        case GET_BANNER: {
            return {
                banner: action.payload.data,
            }
        }
        case UPDATE_BANNER: {
            return {
                ...state,
                banner: action.payload.data,
            }
        }
        case DELETE_BANNER: {
            return {
                ...state,
                banners: state.banners.filter(
                    (item) => item.slug !== action.payload
                ),
            }
        }
        case CLEAR_FIELDS: {
            return {
                banner: action.payload,
            }
        }
        default:
            return state
    }
}
