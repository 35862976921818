import React from "react"
import Slider from "react-slick"
import renderHTML from "react-render-html"

const Testimonial = ({ data }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    }
    return (
        <div className="testimonials_wrapper">
            <Slider {...settings}>
                {data.map((item, index) => {
                    return (
                        <div key={`testimonial-${index}`}>
                            <div className="testinomial_item text-center">
                                <figure>
                                    <img
                                        src={item?.image?.url}
                                        alt={item?.image?.alt}
                                    />
                                </figure>
                                <h3>{item.name}</h3>
                                <h4>Position: {item.position}</h4>
                                <p style={{ fontWeight: 600 }}>
                                    {item?.companyName}
                                </p>
                                {renderHTML(item.description)}
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default Testimonial
