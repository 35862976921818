import React from "react"
import Slider from "react-slick"
import renderHTML from "react-render-html"

import Button from "../../components/Button"

function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <i className="fa  fa-long-arrow-right" />
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <i className="fa  fa-long-arrow-right" />
        </div>
    )
}

const Banner = ({ data }) => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    }
    return (
        <div className="banner-wrapper">
            <Slider {...settings}>
                {data &&
                    data.length > 0 &&
                    data.map((item, index) => {
                        return (
                            <div key={`banner-${index}`}>
                                <div
                                    className="banner-item bg-cover overlay"
                                    style={{
                                        backgroundImage: `url(${item?.image?.url})`,
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="banner-content">
                                                    <h1>{item.name}</h1>
                                                    <h3>
                                                        {renderHTML(
                                                            item?.description
                                                        )}
                                                    </h3>
                                                    <Button
                                                        type="link"
                                                        label="View All"
                                                        className="view_all"
                                                        linkTo="/products"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </Slider>
        </div>
    )
}

export default Banner
