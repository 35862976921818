import React from "react"
import renderHTML from "react-render-html"

const Info = ({ data }) => {
    return (
        <>
            {data && data.length > 0 && (
                <section className="section services_section">
                    <div className="container">
                        {renderHTML(data[0].description)}
                    </div>
                </section>
            )}
        </>
    )
}

export default Info
