import React from "react"
import { Field, ErrorMessage } from "formik"
import TextError from "./TextError"

const Image = (props) => {
    const { label, isMulti, required, name, ...rest } = props
    return (
        <div className="form-group">
            {!isMulti && (
                <label htmlFor={name}>
                    {label}
                    {required && <span style={{ marginLeft: 3 }}>*</span>}
                </label>
            )}
            <Field name={name}>
                {({ form }) => {
                    const { setFieldValue } = form
                    return (
                        <input
                            type="file"
                            {...rest}
                            id={name}
                            className="form-control"
                            onChange={(event) =>
                                setFieldValue(name, event.target.files[0])
                            }
                        />
                    )
                }}
            </Field>
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default Image
