import React from "react"
import { Field, ErrorMessage } from "formik"
import TextError from "./TextError"

function Input(props) {
    const { label, name, required, ...rest } = props
    return (
        <div className="form-group">
            <label htmlFor={name}>
                {label}
                {required && <span style={{ marginLeft: 3 }}>*</span>}
            </label>
            <Field id={name} name={name} {...rest} className="form-control" />
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default Input
