export const LOGIN = "LOGIN"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const REGISTER = "REGISTER"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"

export const LOGOUT = "LOGOUT"
export const LOGOUT_FAIL = "LOGOUT_FAIL"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
