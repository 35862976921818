import React from "react"

import Title from "../../components/Title"
import Products from "../../components/Products"
import Button from "../../components/Button"

const NewProducts = ({ data }) => {
    return (
        <>
            {data && data.length > 0 && (
                <section className="section product-section new_arrival_section bg-gray">
                    <div className="container">
                        <Title
                            title="New arrival Products"
                            className="text-center"
                            bgTitle="New Arrival"
                        />
                        <Products
                            className="box-shadow"
                            itemClass="3"
                            items={data}
                            newProducts={true}
                        />
                        <Button
                            type="link"
                            label="View All"
                            className="view_all"
                            linkTo="/products"
                        />
                    </div>
                </section>
            )}
        </>
    )
}

export default NewProducts
