import React, {useEffect, useState} from "react"
import {shallowEqual, useDispatch, useSelector} from "react-redux"

import {getAllEnquiries} from "../../../store/actions/enquiry"
import {BsFillTrashFill} from "react-icons/bs";
import Api from "../../../store/API/axios";
import {toast} from "react-toastify";
import history from "../../../shared/history";
import {DELETE_ENQUIRY} from "../../../store/constants/enquiry";

const Enquiry = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(false)
        dispatch(getAllEnquiries())
        setIsLoading(false)
    }, [dispatch])
    const { enquiries } = useSelector(
        (state) => ({
            enquiries: state.enquiry.enquiries,
        }),
        shallowEqual
    )

     const deleteEnquiry = (id) => {
        return async (dispatch) => {
            try {
                const res=await Api.delete(`/enquiry/${id}`)
                dispatch({ type: DELETE_ENQUIRY, payload: id })
                console.log(res)
                if (res?.status===204) {
                    history.push("/admin/enquiry")
                    toast.success("Enquiry deleted successfully")
                }
            } catch (err) {
                toast.error("Error Enquiry Enquiry")
            }
        }
    }
    if (isLoading) return <p>Loading...</p>
    return (
        <div className="table_box_content">
            <table>
                <thead>
                    <tr>
                        <th style={{ width: "5%" }}>S.no</th>
                        <th style={{ width: "15%" }}>Name</th>
                        <th style={{ width: "15%" }}>Email</th>
                        <th style={{ width: "10%" }}>Phone number</th>
                        <th style={{ width: "15%" }}>Subject</th>
                        <th style={{ width: "10%" }}>Product</th>
                        <th style={{ width: "25%" }}>Message</th>
                        <th style={{textAlign: "center"}}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {enquiries.map((item, index) => {
                        return (
                            <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.email}</td>
                                <td>{item?.phoneNumber}</td>
                                <td>{item?.subject?item?.subject:'N/A'}</td>
                                <td>{item?.product?.name}</td>
                                <td>{item?.message}</td>
                                <td style={{textAlign: "center"}}>

                                    <span
                                        className="delete_table_item"
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to delete")) {
                                                dispatch(deleteEnquiry(item?.slug))
                                            }
                                        }}
                                    >
                                        <BsFillTrashFill/>
                                    </span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Enquiry
