import React, {useEffect, useState} from "react"
import {shallowEqual, useDispatch, useSelector} from "react-redux"

import {getAllContacts} from "../../../store/actions/contact"
import {BsFillTrashFill} from "react-icons/bs";
import Api from "../../../store/API/axios";
import {toast} from "react-toastify";
import {UPDATE_CONTACTS} from "../../../store/constants/contact";
import history from "../../../shared/history";

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllContacts())
        setIsLoading(false)
    }, [dispatch])
    const {contacts} = useSelector(
        (state) => ({
            contacts: state.contact.contacts,
        }),
        shallowEqual
    )

    const deleteMessage = (item) => {

        console.log("id", item._id)
        return async (dispatch) => {
            try {
                const res = await Api.put(`/contacts/${item._id}`, {
                    message: null
                })
                dispatch({
                    type: UPDATE_CONTACTS, payload: {
                        message: null
                    }
                })

                if (res?.data?.message === "success") {
                    history.push("/admin/contacts")
                    toast.success("Message deleted successfully")
                }
            } catch (err) {
                toast.error("Error deleting Message")
            }
        }
    }

    if (isLoading) return <p>Loading...</p>
    // if (!isLoading && contacts.length === 0) return <p>No Data</p>
    return (
        <div className="table_box_content">
            <table>
                <thead>
                <tr>
                    <th>S.no</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone number</th>
                    <th>Subject</th>
                    <th>Message</th>
                    <th>Message</th>
                </tr>
                </thead>
                <tbody>
                {contacts.length > 0 ? (
                    contacts.map((item, index) => {
                        return (
                            <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.phoneNumber}</td>
                                <td>{item.subject}</td>
                                <td>{item.message || 'N/A'}</td>
                                <td style={{textAlign: "center"}}>
                                    {/*<Link*/}
                                    {/*    to={`/admin/${linkTo}/${item?.slug}`}*/}
                                    {/*    className="edit_table_item"*/}
                                    {/*>*/}
                                    {/*    <BsPencil/>*/}
                                    {/*</Link>*/}
                                    Delete Message
                                    <span
                                        className="delete_table_item"
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to delete message")) {
                                                dispatch(deleteMessage(item))
                                            }
                                        }}
                                    >
                                        <BsFillTrashFill/>
                                    </span>
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <th colSpan="6">No data</th>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default Contact
