import React, { useState, useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import ReactPaginate from "react-paginate"
import { toast } from "react-toastify"

import { getAllCategory, deleteCategory } from "../../../store/actions/product"
import Table from "../../components/Table"
import Search from "../../components/Search"

const CategoryList = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllCategory())
        toast.success("Category fetched successfully")
        setIsLoading(false)
    }, [dispatch])
    const { category } = useSelector(
        (state) => ({
            category: state.product.category,
        }),
        shallowEqual
    )
    const handleSearch = (searchTerm) => {
        dispatch(getAllCategory(searchTerm))
    }
    if (isLoading) {
        return <p>Loading....</p>
    }
    return (
        <>
            <div className="table_box_content">
                <div className="table_header">
                    <h3>Category List</h3>
                    <Search
                        handleSearch={(searchTerm) => handleSearch(searchTerm)}
                    />
                </div>
                <Table
                    data={category}
                    deleteItem={(id) => dispatch(deleteCategory(id))}
                    linkTo="category"
                />
                <div className="table_footer">
                    <ReactPaginate />
                </div>
            </div>
        </>
    )
}

export default CategoryList
