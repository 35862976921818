import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import { Register } from "../store/actions/auth"
import FormikControl from "../admin/components/FormikControl"

const RegisterForm = () => {
    const initialValues = {
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
    }
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email").required("Required"),
        name: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
        password_confirmation: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
        ),
    })
    const dispatch = useDispatch()
    const history = useHistory()
    const onSubmit = (values) => {
        dispatch(Register(values))
    }
    return (
        <div className="auth_form_wrapper">
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    background: "#fff",
                    width: "400px",
                    padding: "25px",
                    borderRadius: "4px",
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => (
                        <Form>
                            <div className="form_inner_content">
                                <FormikControl
                                    control="input"
                                    type="text"
                                    label="Full Name"
                                    name="name"
                                />
                                <FormikControl
                                    control="input"
                                    type="email"
                                    label="Email"
                                    name="email"
                                />
                                <FormikControl
                                    control="input"
                                    type="password"
                                    label="Password"
                                    name="password"
                                />
                                <FormikControl
                                    control="input"
                                    type="password"
                                    label="Password Confirm"
                                    name="password_confirmation"
                                />
                                <button className="submit_btn" type="submit">
                                    Register
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default RegisterForm
