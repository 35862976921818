import {
    ADD_ABOUT,
    GET_ALL_ADD_ABOUT_US,
    GET_ONE_ABOUT,
    UPDATE_ABOUT,
    DELETE_ABOUT,
    CLEAR_FIELDS,
} from "../constants/about"

const initialState = {
    about: [],
    homeAbout: [],
    publishedAboutUs: [],
    singleAbout: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_ABOUT: {
            return {
                ...state,
                about: state.about.concat(action.payload.data),
            }
        }
        case GET_ALL_ADD_ABOUT_US: {
            return {
                ...state,
                about: action.payload.data,
                publishedAboutUs: action.payload.data.filter((item) => {
                    return item.isPublish === true
                }),
                homeAbout: action.payload.data.filter((item) => {
                    return item.isHomeAbout === true
                }),
            }
        }
        case UPDATE_ABOUT: {
            return {
                ...state,
                singleAbout: action.payload.data,
            }
        }
        case GET_ONE_ABOUT: {
            return {
                singleAbout: action.payload.data,
            }
        }
        case DELETE_ABOUT: {
            return {
                ...state,
                about: state.about.filter(
                    (item) => item.slug !== action.payload
                ),
            }
        }
        case CLEAR_FIELDS: {
            return {
                singleAbout: action.payload,
            }
        }
        default:
            return state
    }
}
