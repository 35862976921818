import { GET_SETTING } from "../constants/setting"

const initialState = {
    setting: [],
}
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SETTING: {
            return {
                ...state,
                setting: action.payload.data,
            }
        }
        default:
            return state
    }
}
