import { ADD_CONTACT, GET_ALL_CONTACTS } from "../constants/contact"

const initialState = {
    contacts: [],
    status: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_CONTACT: {
            return {
                ...state,
                status: action.payload?.message,
                contacts: state.contacts.concat(action.payload.data),
            }
        }
        case GET_ALL_CONTACTS: {
            return {
                ...state,
                contacts: action.payload.data,
            }
        }
        default:
            return state
    }
}
