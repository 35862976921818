import {ADD_ENQUIRY, DELETE_ENQUIRY, GET_ALL_ENQUIRY} from "../constants/enquiry"

const initialState = {
    enquiries: [],
    status: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_ENQUIRY: {
            return {
                ...state,
                status: action.payload?.data?.message,
                enquiries: state.enquiries.concat(action.payload.data),
            }
        }
        case GET_ALL_ENQUIRY: {
            return {
                ...state,
                enquiries: action.payload.data,
            }
        }

        case DELETE_ENQUIRY: {
            return {
                ...state,
                enquiries: state.enquiries.filter(
                    (item) => item.slug !== action.payload
                ),
            }
        }
        default:
            return state
    }
}
