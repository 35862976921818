import {
    ADD_FAQ,
    GET_ALL_FAQ,
    GET_SINGLE,
    UPDATE_FAQ,
    DELETE_FAQ,
    CLEAR_FIELDS,
} from "../constants/faq"

const initialState = {
    faq: [],
    publishedFaq: [],
    singleFaq: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_FAQ: {
            return {
                ...state,
                faq: state.faq.concat(action.payload.data),
            }
        }
        case GET_ALL_FAQ: {
            return {
                ...state,
                faq: action.payload.data,
            }
        }
        case GET_SINGLE: {
            return {
                singleFaq: action.payload.data,
            }
        }
        case UPDATE_FAQ: {
            return {
                ...state,
                singleFaq: action.payload.data,
            }
        }
        case DELETE_FAQ: {
            return {
                ...state,
                faq: state.faq.filter((item) => item.slug !== action.payload),
            }
        }
        case CLEAR_FIELDS: {
            return {
                singleFaq: action.payload,
            }
        }
        default:
            return state
    }
}
