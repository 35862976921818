import React from "react"
import { Link } from "react-router-dom"

const Footer = ({ data, products }) => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="footer_item">
                            <h3>Top Products</h3>
                            <ul className="footer_links">
                                {products &&
                                    products.length > 0 &&
                                    products
                                        .filter((item, idx) => idx < 5)
                                        .map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link
                                                        to={`/product/${item?.slug}`}
                                                    >
                                                        {item?.name}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer_item">
                            <h3>Quick Links</h3>
                            <ul className="footer_links">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                <li>
                                    <Link to="/products">Products</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="footer_item">
                            <h3>Find Us</h3>
                            <ul className="footer_address">
                                {data && data[0] && data[0]?.address && (
                                    <li><b>Address:</b> {data[0]?.address}</li>
                                )}
                                {data && data[0] && data[0]?.phone && (
                                    <li><b>Phone:</b> {data[0]?.phone} </li>
                                )}
                                {data && data[0] && data[0]?.email && (
                                    <li><b>Email:</b> {data[0]?.email}</li>
                                )}
                            </ul>
                            <ul className="social_media">
                                {data &&
                                    data[0] &&
                                    data[0]?.socialMedia?.facebook && (
                                        <li className="facebook">
                                            <a
                                                href={
                                                    data[0]?.socialMedia
                                                        ?.facebook
                                                }
                                                target="_blank"
                                            >
                                                <i className="fa fa-facebook" />
                                            </a>
                                        </li>
                                    )}
                                {data &&
                                    data[0] &&
                                    data[0]?.socialMedia?.twitter && (
                                        <li className="twitter">
                                            <a
                                                href={
                                                    data[0]?.socialMedia
                                                        ?.twitter
                                                }
                                                target="_blank"
                                            >
                                                <i className="fa fa-twitter" />
                                            </a>
                                        </li>
                                    )}
                                {data &&
                                    data[0] &&
                                    data[0]?.socialMedia?.youtube && (
                                        <li className="youtube">
                                            <a
                                                href={
                                                    data[0]?.socialMedia
                                                        ?.youtube
                                                }
                                                target="_blank"
                                            >
                                                <i className="fa fa-youtube" />
                                            </a>
                                        </li>
                                    )}
                                {data &&
                                    data[0] &&
                                    data[0]?.socialMedia?.instagram && (
                                        <li className="instagram">
                                            <a
                                                href={
                                                    data[0]?.socialMedia
                                                        ?.instagram
                                                }
                                                target="_blank"
                                            >
                                                <i className="fa fa-instagram" />
                                            </a>
                                        </li>
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
