import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Formik, Form } from "formik"
import { toast } from "react-toastify"
import * as Yup from "yup"

import {
    addBanner,
    getBanner,
    updateBanner,
    clearFields,
} from "../../../store/actions/banner"
import FormikControl from "../../components/FormikControl"

const BannerForm = () => {
    const radioOptions = [
        { key: "Yes", value: "yes" },
        { key: "No", value: "no" },
    ]
    const dispatch = useDispatch()
    const history = useHistory()
    const { slug } = useParams()
    const isAddMode = !slug
    useEffect(() => {
        if (slug) {
            dispatch(getBanner(slug))
            toast.success("Banner read successfull")
        } else {
            dispatch(clearFields((initialValues = {})))
        }
    }, [dispatch, slug])
    const { banner, status } = useSelector(
        (state) => ({
            banner: state.banner.banner,
            status: state.banner.stauts,
        }),
        shallowEqual
    )
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        file: Yup.mixed().required("Required"),
        description: Yup.string().required("Required"),
        isPublish: Yup.string().required("Required"),
    })
    let initialValues = {
        name: banner.name ? banner.name : "",
        file: null,

        alt: banner.image ? banner.image.alt : "",
        caption: banner.image ? banner.image.caption : "",
        display_order: banner.display_order ? banner.display_order : "",
        description: banner.description ? banner.description : "",
        isPublish:
            banner.isPublish === true
                ? "yes"
                : banner.isPublish === false
                ? "no"
                : "",
    }
    const onSubmit = (values) => {
        let {
            name,
            file,
            alt,
            caption,
            display_order,
            description,
            isPublish,
        } = values
        let image = {}
        image.alt = alt
        image.caption = caption
        const dataToSend = new FormData()
        dataToSend.append("name", name)
        dataToSend.append("display_order", display_order)
        dataToSend.append("description", description)
        dataToSend.append("image[alt]", alt)
        dataToSend.append("image[caption]", caption)
        dataToSend.append("isPublish", isPublish)
        dataToSend.append("image", file)
        if (isAddMode) {
            dispatch(addBanner(dataToSend))
            history.push("/admin/banners")
        } else {
            dispatch(updateBanner(slug, dataToSend))
            history.push("/admin/banners")
        }
    }

    return (
        <div className="form_wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {
                    return (
                        <Form>
                            <h1>{isAddMode ? "Banner Form" : "Edit Banner"}</h1>
                            <div className="form_inner_content">
                                <div className="row">
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label="Banner Title"
                                            name="name"
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="input"
                                            type="number"
                                            label="Display Order"
                                            name="display_order"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="image"
                                            label="Banner Image"
                                            name="file"
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label="Banner Image Alt"
                                            name="alt"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label="Banner Image caption"
                                            name="caption"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="radio"
                                            label="Is Publish"
                                            name="isPublish"
                                            options={radioOptions}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <FormikControl
                                            control="textarea"
                                            label="Description"
                                            name="description"
                                            required
                                            value={initialValues.description}
                                        />
                                        <button
                                            className="submit_btn"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default BannerForm
