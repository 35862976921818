import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Formik, Form } from "formik"
import { toast } from "react-toastify"
import * as Yup from "yup"

import {
    addTestimonial,
    getTestimonial,
    updateTestimonial,
    clearFields,
} from "../../../store/actions/testimonial"
import FormikControl from "../../components/FormikControl"

const TestimonialForm = () => {
    const radioOptions = [
        { key: "Yes", value: "yes" },
        { key: "No", value: "no" },
    ]

    const dispatch = useDispatch()
    const { slug } = useParams()
    const isAddMode = !slug
    useEffect(() => {
        if (slug) {
            dispatch(getTestimonial(slug))
            toast.success("Testimonial read successfull")
        } else {
            dispatch(clearFields((initialValues = {})))
        }
    }, [dispatch, slug])
    const { testimonial } = useSelector(
        (state) => ({
            testimonial: state.testimonial.testimonial,
        }),
        shallowEqual
    )
    let initialValues = {
        name: testimonial.name ? testimonial.name : "",
        companyName: testimonial.companyName ? testimonial.companyName : "",
        email: testimonial.email ? testimonial.email : "",
        phone_number: testimonial.phone_number ? testimonial.phone_number : "",
        address: testimonial.address ? testimonial.address : "",
        position: testimonial.position ? testimonial.position : "",
        alt: testimonial.image ? testimonial.image.alt : "",
        caption: testimonial.caption ? testimonial.caption : "",
        url: null,
        display_order: testimonial.display_order
            ? testimonial.display_order
            : "",
        description: testimonial.description ? testimonial.description : "",
        isPublish:
            testimonial.isPublish === true
                ? "yes"
                : testimonial.isPublish === false
                ? "no"
                : "",
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        companyName: Yup.string().required("Required"),
        url: Yup.mixed().required("Required"),
        description: Yup.string().required("Required"),
        isPublish: Yup.string().required("Required"),
    })
    const onSubmit = (values) => {
        const {
            name,
            companyName,
            email,
            phone_number,
            address,
            position,
            alt,
            caption,
            url,
            display_order,
            description,
            isPublish,
        } = values
        let image = {}
        image.alt = alt
        image.caption = caption
        const dataToSend = new FormData()
        dataToSend.append("name", name)
        dataToSend.append("companyName", companyName)
        dataToSend.append("email", email)
        dataToSend.append("phone_number", phone_number)
        dataToSend.append("position", position)
        dataToSend.append("display_order", display_order)
        dataToSend.append("description", description)
        dataToSend.append("isPublish", isPublish)
        dataToSend.append("image", url)
        dataToSend.append("image[alt]", alt)
        dataToSend.append("address", address)
        dataToSend.append("image[caption]", caption)
        if (isAddMode) {
            dispatch(addTestimonial(dataToSend))
        } else {
            dispatch(updateTestimonial(slug, dataToSend))
        }
    }
    return (
        <div className="form_wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form>
                        <h1>
                            {isAddMode
                                ? " Testimonial Form"
                                : "Edit Testimonial"}
                        </h1>
                        <div className="form_inner_content">
                            <div className="row">
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Title"
                                        name="name"
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="email"
                                        label="Company email"
                                        name="email"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Compnay Name"
                                        name="companyName"
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="Phone Number"
                                        name="phone_number"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Position"
                                        name="position"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Company Address"
                                        name="address"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="Display Order"
                                        name="display_order"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="image"
                                        label="Testimonial Image"
                                        name="url"
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="radio"
                                        label="Is Publish"
                                        name="isPublish"
                                        options={radioOptions}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <FormikControl
                                        control="textarea"
                                        label="Description"
                                        name="description"
                                        required
                                    />
                                    <button
                                        className="submit_btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default TestimonialForm
