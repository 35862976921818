export const ADD_CATEGORY = "ADD_CATEGORY"
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY"
export const GET_CATEGORY = "GET_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const DELETE_CATEGORY = "DELETE_CATEGORY"

export const ADD_PRODUCT = "ADD_PRODUCT"
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT"
export const GET_PRODUCT = "GET_PRODUCT"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const GET_FILTERED_PRODUCTS = "GET_FILTERED_PRODUCTS"
export const CLEAR_FIELDS = "CLEAR_FIELDS"
