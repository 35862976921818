import React from "react"

import Title from "../Title"
import ClientItems from "./Clients"

const index = (props) => {
    return (
        <>
            {props.data && props.data.length > 0 && (
                <section
                    className={`section client-section ${
                        props.className ? props.className : ""
                    }`}
                >
                    <div className="container">
                        <Title
                            title="We Have Worked With"
                            className="text-center"
                            bgTitle="We work with"
                        />
                        <ClientItems items={props.data} />
                    </div>
                </section>
            )}
        </>
    )
}

export default index
