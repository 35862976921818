import {
    ADD_TESTIMONIAL,
    GET_ALL_TESTIMONIAL,
    GET_TESTIMONIAL,
    UPDATE_TESTIMONIAL,
    DETETE_TESTIMONIAL,
    CLEAR_FIELDS,
} from "../constants/testimonial"

const initialState = {
    testimonials: [],
    publishedtestimonials: [],
    testimonial: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TESTIMONIAL: {
            return {
                ...state,
                testimonials: state.testimonials.concat(action.payload.data),
            }
        }
        case GET_ALL_TESTIMONIAL: {
            return {
                ...state,
                testimonials: action.payload.data,
                publishedtestimonials: action.payload.data.filter((item) => {
                    return item.isPublish === true
                }),
            }
        }
        case GET_TESTIMONIAL: {
            return {
                testimonial: action.payload.data,
            }
        }
        case UPDATE_TESTIMONIAL: {
            return {
                ...state,
                testimonial: action.payload.data,
            }
        }
        case DETETE_TESTIMONIAL: {
            return {
                ...state,
                testimonials: state.testimonials.filter(
                    (item) => item.slug !== action.payload
                ),
            }
        }
        case CLEAR_FIELDS: {
            return {
                testimonial: action.payload,
            }
        }
        default:
            return state
    }
}
