import React from "react"
import renderHTML from "react-render-html"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

const Specification = ({ data }) => {
    return (
        <section className="section bg-gray specification_wrapper">
            <div className="container">
                <Tabs>
                    <TabList>
                        <Tab>Description</Tab>
                        <Tab>Specification</Tab>
                        <Tab>Additional Info</Tab>
                    </TabList>
                    <TabPanel>
                        {data.description && renderHTML(data.description)}
                    </TabPanel>
                    <TabPanel>
                        <div className="specification_table">
                            <table>
                                <tbody>
                                    {data?.sku && (
                                        <tr>
                                            <td>Sku</td>
                                            <td> {data.sku}</td>
                                        </tr>
                                    )}
                                    {data?.dimension && (
                                        <tr>
                                            <td>Dimension</td>
                                            <td> {data.dimension}</td>
                                        </tr>
                                    )}
                                    {data?.brand && (
                                        <tr>
                                            <td>Brand</td>
                                            <td> {data.brand}</td>
                                        </tr>
                                    )}
                                    {data?.manufacturer && (
                                        <tr>
                                            <td>Manufacturer</td>
                                            <td> {data.manufacturer}</td>
                                        </tr>
                                    )}
                                    {data?.weight && (
                                        <tr>
                                            <td>Weight</td>
                                            <td> {data.weight}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        {data.additional_information &&
                            renderHTML(data.additional_information)}
                    </TabPanel>
                </Tabs>
            </div>
        </section>
    )
}

export default Specification
