import React, { useEffect } from "react"

import Title from "../../components/Title"
import Products from "../../components/Products"

const RelatedProducts = ({ data }) => {
    return (
        <>
            {data && data.length > 0 && (
                <section className="section related_products_section">
                    <div className="container">
                        <Title
                            title="Related Products"
                            // className="text-center"
                            bgTitle="Related Products"
                        />
                        <Products
                            slider
                            dots={false}
                            arrows={true}
                            autoPlay={false}
                            items={data}
                            relatedproducts={true}
                        />
                    </div>
                </section>
            )}
        </>
    )
}

export default RelatedProducts
