import Api from "../API/axios"
import { toast } from "react-toastify"
import {
    ADD_SERVICE,
    GET_ALL_SERVICES,
    GET_SERVICE,
    UPDATE_SERVICE,
    CLEAR_FIELDS,
    DELETE_SERVICE,
} from "../constants/service"
import history from "../../shared/history"

export const addService = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post("/services", data)
            if (res?.data?.message === "success") {
                toast.success("service added successfully")
                history.push("/admin/services")
            }
            dispatch({ type: ADD_SERVICE, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getAllServices = (searchTerm) => {
    const query = searchTerm ? searchTerm : ""
    return async (dispatch) => {
        try {
            const res = await Api.get(`/services?search=${query}`)
            dispatch({ type: GET_ALL_SERVICES, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const getService = (id) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/services/${id}`)
            dispatch({ type: GET_SERVICE, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const updateService = (id, data) => {
    return async (dispatch) => {
        try {
            const res = await Api.put(`/services/${id}`, data)
            if (res?.data?.message === "success") {
                toast.success("Service updated successfully")
                history.push("/admin/services")
            }
            dispatch({ type: UPDATE_SERVICE, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const deleteService = (id) => {
    return async (dispatch) => {
        try {
            await Api.delete(`/services/${id}`)
            dispatch({ type: DELETE_SERVICE, payload: id })
            toast.success("Service deleted successfully")
        } catch (err) {
            toast.error("Error deleting Service")
        }
    }
}

export const clearFields = (data) => {
    return {
        type: CLEAR_FIELDS,
        payload: data,
    }
}
