import React, { useEffect } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"

import { getSetting } from "../../store/actions/setting"
import Breadcrumb from "../../components/Breadcrumb"
import Detail from "./Detail"
import Form from "./Form"
import Map from "../../components/Map"
import contact_bg from "../../assets/images/contact_bg.jpg"

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSetting())
    }, [dispatch])
    const { setting } = useSelector(
        (state) => ({
            setting: state.setting.setting,
        }),
        shallowEqual
    )

    return (
        <div className="innerpage_wrapper">
            <Breadcrumb title="Contact us" backgroundImage={contact_bg} />
            <section className="section contact_detail_wrapper bg-gray">
                <div className="container">
                    <Form />
                </div>
            </section>
            <div className="map_wrapper">
                <div className="map_section">
                    <Map data={setting} />
                </div>
                <div className="contact_information_detail_section">
                    <Detail data={setting} />
                </div>
            </div>
        </div>
    )
}

export default ContactPage
