import React, { useState } from "react"
import renderHTML from "react-render-html"

import Button from "../../components/Button"
import Popup from "../../components/Popup"

const Info = ({ data }) => {
    const [showDialog, setShowDialog] = useState(false)
    const handlePopup = () => {
        setShowDialog(true)
    }
    const handleCancel = () => {
        setShowDialog(false)
    }
    return (
        <>
            <div className="product-info-wrapper">
                <h3>{data?.name}</h3>
                {data &&
                    data.shortDescription &&
                    renderHTML(data.shortDescription)}
                <h5>Features</h5>
                {data && data.features && renderHTML(data.features)}
                <Button
                    type="button"
                    label="Enquiry Now"
                    className="view_all"
                    onClick={handlePopup}
                />
            </div>
            <Popup
                initState={showDialog}
                handleCancel={handleCancel}
                selectedId={{ name: data?.name, value: data._id }}
            />
        </>
    )
}

export default Info
