import React from "react";

import Header from "../admin/components/Header";
import Sidebar from "../admin/components/Sidebar";
import "../admin/scss/admin.scss";
import {Login} from "../store/actions/auth";

const Admin = (props) => {

        return (
            <div className="admin_content_wrapper">
                <Header/>
                <Sidebar/>
                {props.children}
            </div>
        )
};

export default Admin;
