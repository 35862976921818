import React, { useRef, useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

import { addEnquiry } from "../store/actions/enquiry"
import Button from "./Button"
import TextError from "../admin/components/TextError"
import FormikControl from "../admin/components/FormikControl"

const Popup = ({ initState, handleCancel, products, selectedId }) => {
    const initialValues = {
        name: "",
        email: "",
        phoneNumber: "",
        product: selectedId
            ? { label: selectedId.name, value: selectedId.value }
            : undefined,
        address: "",
        landLineNumber: "",
        message: "",
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        phoneNumber: Yup.string().min(10).required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        address: Yup.string().required("Required"),
        message: Yup.string().required("Required"),
    })
    let productsOptions = []
    if (products && products.length > 0) {
        products.map((item) => {
            return productsOptions.push({ value: item._id, label: item.name })
        })
    }
    const { status } = useSelector(
        (state) => ({
            status: state.enquiry.status,
        }),
        shallowEqual
    )
    const dispatch = useDispatch()
    const onSubmit = (values, { setSubmitting, resetForm }) => {
        let product = values.product && values.product.value
        const data = { ...values, product }
        dispatch(addEnquiry(data)).then(() => {
            resetForm({})
            setSubmitting(false)
        })
    }
    const formref = useRef(null)
    useEffect(() => {
        setTimeout(function () {
            if (status) {
                formref.current.style.display = "none"
            }
        }, 3000)
    }, [status])
    return (
        <div className={`popup-wrapper ${initState ? "show" : ""}`}>
            <div className="popup-content-wrapper">
                <div className="popup_header">
                    <h3>ENQUIRY FORM</h3>
                </div>
                <div className="popup_content">
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => (
                            <Form>
                                <div className="form_inner_content">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="Title"
                                                name="name"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="input"
                                                type="email"
                                                label="Email"
                                                name="email"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="multiSelect"
                                                label="Select Product"
                                                name="product"
                                                options={productsOptions}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="input"
                                                type="number"
                                                label="Phone Number"
                                                name="phoneNumber"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="input"
                                                type="text"
                                                label="address"
                                                name="address"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormikControl
                                                control="input"
                                                type="number"
                                                label="Land Line Number"
                                                name="landLineNumber"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <Field
                                                    as="textarea"
                                                    id="message"
                                                    name="message"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name="message"
                                                    component={TextError}
                                                />
                                            </div>
                                            {/* <button
                                                className="view_all"
                                                type="submit"
                                            >
                                                Send message
                                            </button> */}
                                            <div className="popup_footer">
                                                <Button
                                                    type="button"
                                                    label={
                                                        formik.isSubmitting
                                                            ? "Loading"
                                                            : "Submit"
                                                    }
                                                    className="view_all"
                                                    action="submit"
                                                />
                                                <Button
                                                    type="button"
                                                    label="Close"
                                                    className="cancel_btn"
                                                    onClick={handleCancel}
                                                />
                                            </div>
                                            {status && (
                                                <p
                                                    className="sucess_message"
                                                    ref={formref}
                                                >
                                                    Message send successfully
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default Popup
