export const items = [
    { name: "Dashboard", link: "/admin/dashboard" },
    {
        name: "Banner Management",
        link: null,
        items: [
            { name: "Add Banner", link: "/admin/banner/add" },
            { name: "Banner List", link: "/admin/banners" },
        ],
    },
    {
        name: "About Us Management",
        link: null,
        items: [
            { name: "Add About", link: "/admin/about/add" },
            { name: "About List", link: "/admin/about" },
        ],
    },
    {
        name: "Product Management",
        link: null,
        items: [
            {
                name: "Add Product Category",
                link: "/admin/add_category",
            },
            {
                name: "Category List",
                link: "/admin/category_list",
            },
            { name: "Add Product", link: "/admin/product/add" },
            { name: "Products List", link: "/admin/products" },
        ],
    },
    {
        name: "Testimonial Management",
        link: null,
        items: [
            { name: "Add Testimonial", link: "/admin/testimonial/add" },
            { name: "Testimonial List", link: "/admin/testimonials" },
        ],
    },
    {
        name: "Services Management",
        link: null,
        items: [
            { name: "Add Service", link: "/admin/services/add" },
            { name: "Services List", link: "/admin/services" },
        ],
    },
    { name: "Contact Management", link: "/admin/contacts" },
    {
        name: "Client Management",
        link: null,
        items: [
            { name: "Add client", link: "/admin/client/add" },
            { name: "Client List", link: "/admin/clients" },
        ],
    },
    {
        name: "Faq Management",
        link: null,
        items: [
            { name: "Add Faq", link: "/admin/faq/add" },
            { name: "Faq List", link: "/admin/faq" },
        ],
    },
    { name: "Enquiry", link: "/admin/enquiry" },
    { name: "Site Management", link: "/admin/setting" },
]
