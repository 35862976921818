import Api from "../API/axios"
import {toast} from "react-toastify"
import {
    ADD_PRODUCT,
    ADD_CATEGORY,
    GET_ALL_CATEGORY,
    GET_ALL_PRODUCT,
    GET_CATEGORY,
    GET_PRODUCT,
    UPDATE_CATEGORY,
    UPDATE_PRODUCT,
    DELETE_CATEGORY,
    DELETE_PRODUCT,
    GET_FILTERED_PRODUCTS,
    CLEAR_FIELDS,
} from "../constants/product"
import history from "../../shared/history"

export const AddCategory = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post("/products/category", data)
            if (res?.data?.message === "success") {
                toast.success("Category added successfully")
                history.push("/admin/category_list")
            }
            dispatch({type: ADD_CATEGORY, payload: res.data})
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getAllCategory = () => {
    return async (dispatch) => {
        try {
            const res = await Api.get("/products/category")
            dispatch({type: GET_ALL_CATEGORY, payload: res.data})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getCategory = (id) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/products/category/${id}`)
            dispatch({type: GET_CATEGORY, payload: res.data})
        } catch (err) {
            console.log(err)
        }
    }
}

export const updateCategory = (id, data) => {
    return async (dispatch) => {
        try {
            const res = await Api.put(`/products/category/${id}`, data)
            if (res?.data?.message === "success") {
                toast.success("Category updated successfully")
                history.push("/admin/category_list")
            }
            dispatch({type: UPDATE_CATEGORY, payload: res.data})
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const deleteCategory = (id) => {
    return async (dispatch) => {
        try {
            await Api.delete(`/products/category/${id}`)
            dispatch({type: DELETE_CATEGORY, payload: id})
            toast.success("Category deleted successfully")
        } catch (err) {
            toast.error("Error deleting Category")
            console.log(err)
        }
    }
}

export const clearFields = (data) => {
    return {
        type: CLEAR_FIELDS,
        payload: data,
    }
}

export const addProduct = (data) => {
    return async (dispatch) => {
        try {
            console.log(data)
            const res = await Api.post("/products", data)
            if (res?.data?.message === "success") {
                toast.success("Product added successfully")
                history.push("/admin/products")
            }
            dispatch({type: ADD_PRODUCT, payload: res.data})
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getAllProducts = () => {
    return async (dispatch) => {
        try {
            const res = await Api.get("/products")

            dispatch({type: GET_ALL_PRODUCT, payload: res.data})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getProductsWithPagination = (pageNumber) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/products/${pageNumber}`)

            dispatch({type: GET_ALL_PRODUCT, payload: res.data})
        } catch (err) {
            console.log(err)
        }
    }
}

export const getProduct = (id) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/products/${id}`)
            dispatch({type: GET_PRODUCT, payload: res.data})
        } catch (err) {
            console.log(err)
        }
    }
}
export const updateProduct = (id, data) => {
    return async (dispatch) => {
        try {
            const res = await Api.put(`/products/${id}`, data)
            if (res?.data?.message === "success") {
                toast.success("Product updated successfully")
                history.push("/admin/products")
            }
            dispatch({type: UPDATE_PRODUCT, payload: res.data})
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}
export const deleteProduct = (id) => {
    return async (dispatch) => {
        try {
            await Api.delete(`/products/${id}`)
            dispatch({type: DELETE_PRODUCT, payload: id})
            toast.success("Product deleted successfully")
        } catch (err) {
            toast.error("Error deleting Product")
        }
    }
}

export const getProductByCategory = (id, number) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(
                `/products/getProduct/byCategory?query=${id}&pageNumber=${
                    number && Object.values(number)[0] + 1
                }`
            )
            dispatch({type: GET_FILTERED_PRODUCTS, payload: res})
        } catch (err) {
            console.log(err)
        }
    }
}
