import Api from "../API/axios"
import { GET_SETTING, UPDATE_SETTING } from "../constants/setting"
import { toast } from "react-toastify"

export const updateSetting = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post("/settings", data)
            dispatch({ type: UPDATE_SETTING, payload: res.data })
            toast.success("Setting updated successfully")
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getSetting = () => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/settings`)
            dispatch({ type: GET_SETTING, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}
