import React, { useState, useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import ReactPaginate from "react-paginate"
import { toast } from "react-toastify"

import { getAllFaq, deleteFaq } from "../../../store/actions/faq"
import Table from "../../components/Table"
import Search from "../../components/Search"

const Faq = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllFaq())
        toast.success("faq fetched successfully")
        setIsLoading(false)
    }, [dispatch])
    const { faq } = useSelector(
        (state) => ({
            faq: state.faq.faq,
        }),
        shallowEqual
    )
    const handleSearch = (searchTerm) => {
        dispatch(getAllFaq(searchTerm))
    }
    if (isLoading) {
        return <p>Loading....</p>
    }
    return (
        <>
            <div className="table_box_content">
                <div className="table_header">
                    <h3>Banner List</h3>
                    <Search
                        handleSearch={(searchTerm) => handleSearch(searchTerm)}
                    />
                </div>
                <Table
                    data={faq}
                    deleteItem={(id) => dispatch(deleteFaq(id))}
                    linkTo="faq"
                />
                <div className="table_footer">
                    <ReactPaginate />
                </div>
            </div>
        </>
    )
}

export default Faq
