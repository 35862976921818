import no_image from "../../../assets/images/no_image.png";
import {Link} from "react-router-dom";
import {BsFillTrashFill, BsPencil} from "react-icons/bs";
import React from "react";

const TestimonialTable = ({data, deleteItem, linkTo}) => {

    return (
        <table>
            <thead>
            <tr>
                <th style={{textAlign: "center"}}>Id</th>
                <th style={{textAlign: "center"}}>Title</th>
                <th style={{textAlign: "center"}}>Position</th>
                <th style={{textAlign: "center"}}>Image</th>
                <th style={{textAlign: "center"}}>Is Publish</th>
                <th style={{textAlign: "center"}}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {(data && data.length === 0) || !data ? (
                <tr>
                    <td
                        colSpan="6"
                        style={{textAlign: "center", padding: 10}}
                    >
                        No Items
                    </td>
                </tr>
            ) : (
                data &&
                data.map((item, index) => {
                    return (
                        <tr key={item._id}>
                            <td style={{textAlign: "center"}}>
                                {index + 1}
                            </td>
                            <td style={{textAlign: "center"}}>
                                {item?.name}
                            </td>
                            <td style={{textAlign: "center"}}>
                                {item?.position}
                            </td>
                            {item.image ? (
                                <td style={{textAlign: "center"}}>
                                    <img
                                        style={{
                                            width: "80px",
                                            height: "80px",
                                            objectFit: "cover",
                                            objectPosition: "top",
                                            margin: "0 auto",
                                        }}
                                        src={item.image.url ? item.image.url : item.image}
                                        alt={item?.image?.alt}
                                    />
                                </td>
                            ) : (
                                <td style={{textAlign: "center"}}>
                                    <img
                                        style={{
                                            width: "50px",
                                            height: "80px",
                                            objectFit: "cover",
                                            objectPosition: "top",
                                            margin: "0 auto",
                                        }}
                                        src={no_image}
                                        alt={item?.image?.alt}
                                    />
                                </td>
                            )}
                            <td style={{textAlign: "center"}}>
                                {item?.isPublish === true ? "Yes" : "No"}
                            </td>
                            <td style={{textAlign: "center"}}>
                                <Link
                                    to={`/admin/${linkTo}/${item?.slug}`}
                                    className="edit_table_item"
                                >
                                    <BsPencil/>
                                </Link>
                                <span
                                    className="delete_table_item"
                                    onClick={() => {
                                        if (window.confirm("Are you sure you want to delete")) {
                                            deleteItem(item?.slug)
                                        }
                                    }}
                                >
                                        <BsFillTrashFill/>
                                    </span>
                            </td>
                        </tr>
                    )
                })
            )}
            </tbody>
        </table>
    )


}

export default TestimonialTable
