import React from "react"

const TopHeader = ({ data }) => {
    return (
        <div className="top-header bg-gray">
            <div className="container">
                <div className="header-left topHeader_item">
                    <ul className="topHeader_detail">
                        <li>
                            <span className="fa fa-phone" />
                            <a href="tel:+496170961709">
                                {data && data[0] && data[0]?.phone}
                            </a>
                        </li>
                        <li>
                            <span className="fa fa-envelope-o" />
                            <a href="mailto:email@example.com">
                                {data && data[0] && data[0]?.email}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="header-right topHeader_item">
                    <ul className="header_social_links">
                        { data[0]?.socialMedia?.facebook?
                        <li>
                            <a
                                href={
                                    data &&
                                    data[0] &&
                                    data[0].socialMedia?.facebook
                                }

                                target="_blank"
                                className="facebook"
                            >
                                <i className="fa fa-facebook" />
                            </a>
                        </li>:''}

                        { data[0]?.socialMedia?.twitter?
                        <li>
                            <a
                                href={
                                    data &&
                                    data[0] &&
                                    data[0].socialMedia?.twitter
                                }

                                target="_blank"
                                className="twitter"
                            >
                                <i className="fa fa-twitter" />
                            </a>
                        </li>:''}
                        { data[0]?.socialMedia?.youtube?
                        <li>
                            <a
                                href={
                                    data &&
                                    data[0] &&
                                    data[0].socialMedia?.youtube
                                }

                                target="_blank"
                                className="youtube"
                            >
                                <i className="fa fa-youtube" />
                            </a>
                        </li>:''}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TopHeader
