import React, { useState, useEffect } from "react"

const Sidebar = ({ data, handleCategoryId, handleReset }) => {
    const [categoryId, setCategoryId] = useState("")
    useEffect(() => {
        handleCategoryId(categoryId)
    }, [categoryId])
    return (
        <>
            {data && data.length > 0 && (
                <div className="product_sidebar">
                    <ul>
                        <li onClick={() => handleReset()}>All</li>
                        {data.map((item) => {
                            return (
                                <li
                                    key={item._id}
                                    onClick={() => {
                                        setCategoryId(item._id)
                                    }}
                                >
                                    {item.name}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </>
    )
}

export default Sidebar
