import Api from "../API/axios"
import { ADD_ENQUIRY, GET_ALL_ENQUIRY } from "../constants/enquiry"
import { toast } from "react-toastify"

export const addEnquiry = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post(`/enquiry`, data)

            dispatch({ type: ADD_ENQUIRY, payload: res.data })
            return res
        } catch (err) {
            toast.error(err?.response?.data?.message)
            console.log(err)
        }
    }
}

export const getAllEnquiries = () => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/enquiry`)
            dispatch({ type: GET_ALL_ENQUIRY, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
            console.log(err)
        }
    }
}
export const deleteEnquiry = (id) => {
    return async (dispatch) => {}
}
export const getEnquiry = (id) => {
    return async (dispatch) => {}
}
