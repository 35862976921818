import Api from "../API/axios"
import {
    ADD_TESTIMONIAL,
    GET_ALL_TESTIMONIAL,
    GET_TESTIMONIAL,
    UPDATE_TESTIMONIAL,
    DETETE_TESTIMONIAL,
    CLEAR_FIELDS,
} from "../constants/testimonial"
import { toast } from "react-toastify"
import history from "../../shared/history"

export const addTestimonial = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post("/testimonials", data)
            if (res?.data?.message === "success") {
                toast.success("Testimonial added successfully")
                history.push("/admin/testimonials")
            }
            dispatch({ type: ADD_TESTIMONIAL, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getAllTestimonials = () => {
    return async (dispatch) => {
        try {
            const res = await Api.get("/testimonials")
            dispatch({ type: GET_ALL_TESTIMONIAL, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const getTestimonial = (id) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/testimonials/${id}`)
            dispatch({ type: GET_TESTIMONIAL, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const updateTestimonial = (id, data) => {
    return async (dispatch) => {
        try {
            const res = await Api.put(`/testimonials/${id}`, data)
            if (res?.data?.message === "success") {
                toast.success("Testimonial updated successfully")
                history.push("/admin/testimonials")
            }
            dispatch({ type: UPDATE_TESTIMONIAL, payload: res.data })
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const deleteTestimonial = (id) => {
    return async (dispatch) => {
        try {
            await Api.delete(`/testimonials/${id}`)
            dispatch({ type: DETETE_TESTIMONIAL, payload: id })
            toast.success("Testimonial deleted successfully")
        } catch (err) {
            toast.error("Error deleting Testimonial")
        }
    }
}

export const clearFields = (data) => {
    return {
        type: CLEAR_FIELDS,
        payload: data,
    }
}
