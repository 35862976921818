import React from "react"
import {useDispatch} from "react-redux"
import {useHistory} from "react-router-dom"
import {Form, Formik} from "formik"
import * as Yup from "yup"

import {Login} from "../store/actions/auth"
import FormikControl from "../admin/components/FormikControl"

const LoginForm = () => {
    const initialValues = {
        email: "",
        password: "",
        status: '',
    }
    const validationSchema = Yup.object({
        // email: Yup.string().email("Invalid username").required("Required"),
        password: Yup.string().required("Required"),
    })
    const dispatch = useDispatch()
    const history = useHistory()
    const onSubmit = (values) => {
         dispatch(Login(values))



    }
    return (
        <div className="auth_form_wrapper">
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    background: "#fff",
                    width: "400px",
                    padding: "25px",
                    borderRadius: "4px",
                }}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => (
                        <Form>
                            <h1>Login Form</h1>
                            <div className="form_inner_content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label="Username"
                                            name="email"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormikControl
                                            control="input"
                                            type="password"
                                            label="Password"
                                            name="password"
                                        />
                                    </div>

                                    {initialValues.status === 'N'?
                                    <div style={{}}>
                                        Login Failed!
                                    </div>:""}


                                    <button
                                        className="submit_btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default LoginForm
