import React, { useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import { updateSetting, getSetting } from "../../../store/actions/setting"
import FormikControl from "../../components/FormikControl"

const PageSetting = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSetting())
    }, [dispatch])
    const { setting } = useSelector(
        (state) => ({
            setting: state.setting.setting,
        }),
        shallowEqual
    )
    let initialValues = {
        name:
            setting && setting.length > 0 && setting[0].name
                ? setting[0].name
                : "",
        image: null,
        address:
            setting && setting.length > 0 && setting[0].address
                ? setting[0].address
                : "",
        phone:
            setting && setting.length > 0 && setting[0].phone
                ? setting[0].phone
                : "",
        email:
            setting && setting.length > 0 && setting[0].email
                ? setting[0].email
                : "",
        image: null,
        lat:
            setting && setting.length > 0 && setting[0].lat
                ? setting[0].lat
                : "",
        lng:
            setting && setting.length > 0 && setting[0].lng
                ? setting[0].lng
                : "",
        description:
            setting && setting.length > 0 && setting[0].description
                ? setting[0].description
                : "",
        socialMedia: {
            facebook:
                setting && setting.length > 0 && setting[0].socialMedia.facebook
                    ? setting[0].socialMedia.facebook
                    : "",
            youtube:
                setting && setting.length > 0 && setting[0].socialMedia.youtube
                    ? setting[0].socialMedia.youtube
                    : "",
            instagram:
                setting &&
                setting.length > 0 &&
                setting[0].socialMedia.instagram
                    ? setting[0].socialMedia.instagram
                    : "",
            linkedIn:
                setting && setting.length > 0 && setting[0].socialMedia.linkedIn
                    ? setting[0].socialMedia.linkedIn
                    : "",
        },
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        image: Yup.mixed().required("Required"),
        phone: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
    })
    const onSubmit = (values) => {
        let {
            name,
            image,
            address,
            phone,
            email,
            lat,
            lng,
            description,
            socialMedia,
        } = values
        const dataToSend = new FormData()
        dataToSend.append("name", name)
        dataToSend.append("image", image)
        dataToSend.append("address", address)
        dataToSend.append("phone", phone)
        dataToSend.append("email", email)
        dataToSend.append("lat", lat)
        dataToSend.append("lng", lng)
        dataToSend.append("description", description)
        dataToSend.append("socialMedia[facebook]", socialMedia.facebook)
        dataToSend.append("socialMedia[youtube]", socialMedia.youtube)
        dataToSend.append("socialMedia[instagram]", socialMedia.instagram)
        dataToSend.append("socialMedia[linkedIn]", socialMedia.linkedIn)
        dispatch(updateSetting(dataToSend))
    }
    return (
        <div className="form_wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form>
                        <h1>Site Setting</h1>
                        <div className="form_inner_content">
                            <div className="row">
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Website Name"
                                        name="name"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="email"
                                        label="Company email"
                                        name="email"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Address"
                                        name="address"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="Phone Number"
                                        name="phone"
                                    />
                                </div>

                                <div className="col-md-4">
                                    <FormikControl
                                        control="image"
                                        label="Website Logo"
                                        name="image"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="latitude"
                                        name="lat"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="longitude"
                                        name="lng"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Facebook Link"
                                        name="socialMedia.facebook"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Youtube Link"
                                        name="socialMedia.youtube"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Instagram Link"
                                        name="socialMedia.instagram"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="linkedIn Link"
                                        name="socialMedia.linkedIn"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <FormikControl
                                        control="textarea"
                                        label="Description"
                                        name="description"
                                    />
                                    <button
                                        className="submit_btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default PageSetting
