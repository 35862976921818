import React, {useEffect} from "react"
import {useParams} from "react-router-dom"
import {shallowEqual, useDispatch, useSelector} from "react-redux"
import {FieldArray, Form, Formik} from "formik"
import {toast} from "react-toastify"
import * as Yup from "yup"

import {addProduct, clearFields, getAllCategory, getProduct, updateProduct,} from "../../../store/actions/product"
import FormikControl from "../../components/FormikControl"

const ProductForm = () => {
    const radioOptions = [
        { key: "Yes", value: "yes" },
        { key: "No", value: "no" },
    ]
    const { category, product } = useSelector(
        (state) => ({
            category: state.product.category,
            product: state.product.product,
        }),
        shallowEqual
    )
    const dispatch = useDispatch()
    const { slug } = useParams()
    const isAddMode = !slug
    useEffect(() => {
        dispatch(getAllCategory())
    }, [dispatch])
    useEffect(() => {
        if (slug) {
            dispatch(getProduct(slug))
            toast.success("Product read successfull")
        } else {
            dispatch(clearFields((initialValues = {})))
        }
    }, [dispatch, slug])
    const categoryOptions = []
    if (category && category.length > 0) {
        category.map((item) => {
            return categoryOptions.push({ value: item._id, label: item.name })
        })
    }

    let initialValues = {
        sku: product.sku ? product.sku : "",
        name: product.name ? product.name : "",
        category: product.category
            ? { label: product.category.name, value: product.category._id }
            : undefined,
        alt: product.image ? product.image.alt : "",
        caption: product.image ? product.image.caption : "",
        url: null,
        isPublish:
            product.isPublish === true
                ? "yes"
                : product.isPublish === false
                ? "no"
                : "",
        isFeatured:
            product.isFeatured === true
                ? "yes"
                : product.isFeatured === false
                ? "no"
                : "",
        product_attribute: product.product_attribute
            ? product.product_attribute
            : "",
        features: product.features ? product.features : "",
        images: [null],
        dimension: product.dimension ? product.dimension : "",
        weight: product.weight ? product.weight : "",
        brand: product.brand ? product.brand : "",
        manufacturer: product.manufacturer ? product.manufacturer : "",
        description: product.description ? product.description : "",
        shortDescription: product.shortDescription
            ? product.shortDescription
            : "",
        additional_information: product.additional_information
            ? product.additional_information
            : "",
        metaTitle: product.metaTitle ? product.metaTitle : "",
        metaDescription: product.metaDescription ? product.metaDescription : "",
        metaKeyWord: product.metaKeyWord ? product.metaKeyWord : "",
    }
    const validationSchema = Yup.object({
        name: Yup.mixed().required("Required"),
        isPublish: Yup.string().required("Required"),
        // url: Yup.mixed().required("Required")
    })
    const onSubmit = (values) => {
        let categoryId = values.category && values.category.value
        const {
            sku,
            name,
            category,
            alt,
            caption,
            url,
            isPublish,
            product_attribute,
            dimension,
            weight,
            features,
            images,
            brand,
            shortDescription,
            manufacturer,
            description,
            additional_information,
            metaTitle,
            metaDescription,
            metaKeyWord,
            isFeatured,
        } = values
        let image = {}
        image.alt = alt
        image.caption = caption
        const dataToSend = new FormData()
        dataToSend.append("name", name)
        dataToSend.append("sku", sku)
        dataToSend.append("isPublish", isPublish)
        dataToSend.append("product_attribute", product_attribute)
        dataToSend.append("dimension", dimension)
        dataToSend.append("weight", weight)
        dataToSend.append("brand", brand)
        dataToSend.append("manufacturer", manufacturer)
        dataToSend.append("description", description)
        dataToSend.append("additional_information", additional_information)
        dataToSend.append("metaTitle", metaTitle)
        dataToSend.append("metaDescription", metaDescription)
        dataToSend.append("metaKeyWord", metaKeyWord)
        dataToSend.append("image[alt]", alt)
        dataToSend.append("image[caption]", caption)
        category && dataToSend.append("category", categoryId)
        category && dataToSend.append("isFeatured", isFeatured)
        category && dataToSend.append("shortDescription", shortDescription)
        category && dataToSend.append("features", features)
        dataToSend.append("image", url)
        images &&
            images.length > 0 &&
            images.forEach((item) => dataToSend.append("productImages", item))
        if (isAddMode) {
            dispatch(addProduct(dataToSend))
        } else {
            dispatch(updateProduct(slug, dataToSend))
        }
    }
    return (
        <div className="form_wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form>
                        <h1>{isAddMode ? " Product Form" : "Edit Product"}</h1>
                        <div className="form_inner_content">
                            <div className="row">
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Product SKU"
                                        name="sku"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Product Name"
                                        name="name"
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="multiSelect"
                                        label="Product Category"
                                        name="category"
                                        options={categoryOptions}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="radio"
                                        label="Publish"
                                        name="isPublish"
                                        required
                                        options={radioOptions}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="radio"
                                        label="is Featured"
                                        name="isFeatured"
                                        options={radioOptions}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="image"
                                        label="Product Image"
                                        name="url"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        label="Product Image Alt"
                                        name="alt"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        label="Product Image Caption"
                                        name="caption"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        label="Dimension"
                                        name="dimension"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="Weight"
                                        name="weight"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Brand"
                                        name="brand"
                                    />
                                </div>

                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        label="Manufacturer"
                                        name="manufacturer"
                                    />
                                </div>
                                {/* <div className="col-md-4">

                                </div> */}
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        label="Meta Title"
                                        name="metaTitle"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        label="Meta Description"
                                        name="metaDescription"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        label="Meta Keyword"
                                        name="metaKeyWord"
                                    />
                                </div>
                                <>
                                    <FieldArray name="images">
                                        {(fieldArrayProps) => {
                                            const {
                                                push,
                                                remove,
                                                form,
                                            } = fieldArrayProps
                                            const { values } = form
                                            const { images } = values
                                            return (
                                                <>
                                                    {images.map(
                                                        (img, index) => (
                                                            <div
                                                                key={index}
                                                                className="col-md-4"
                                                            >
                                                                <div className="form-group field_array">
                                                                    <>
                                                                        {" "}
                                                                        <label>
                                                                            {`Product
                                                                        Image 
                                                                        ${
                                                                            index +
                                                                            1
                                                                        }
                                                                        `}
                                                                        </label>
                                                                        <span>
                                                                            *
                                                                        </span>{" "}
                                                                    </>
                                                                    <FormikControl
                                                                        control="image"
                                                                        label="Product Image"
                                                                        name={`images[${index}]`}
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />

                                                                    {index >
                                                                    0 ? (
                                                                        <button
                                                                            type="button"
                                                                            className="remove_item"
                                                                            onClick={() =>
                                                                                remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                            style={{
                                                                                right:
                                                                                    "0px",
                                                                            }}
                                                                        >
                                                                            -
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className={`add_item ${
                                                                                index >
                                                                                0
                                                                                    ? "right-45"
                                                                                    : "right-0"
                                                                            }`}
                                                                            onClick={() =>
                                                                                push(
                                                                                    ""
                                                                                )
                                                                            }
                                                                        >
                                                                            +
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </>
                                            )
                                        }}
                                    </FieldArray>
                                </>

                                <div className="col-md-12">
                                    <FormikControl
                                        control="textarea"
                                        label="Product Short Description"
                                        name="shortDescription"
                                    />
                                    <FormikControl
                                        control="textarea"
                                        label="Product Description"
                                        name="description"
                                        required
                                    />

                                    <FormikControl
                                        control="textarea"
                                        label="Additional Information"
                                        name="additional_information"
                                    />
                                    <FormikControl
                                        control="textarea"
                                        label="Product Features"
                                        name="features"
                                    />
                                    <button
                                        className="submit_btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ProductForm
