import React from "react"
import history from "../../shared/history";
import {LOGOUT} from "../../store/constants/auth";
import {useDispatch} from "react-redux";
import Button from "./Button";


const handlelogout = (dispatch) => {
    dispatch({type: LOGOUT})
    history.push("/login")
}

const Header = () => {
    const dispatch = useDispatch()
    return (
        <header className="header">
            <div className="header_inner">
                <div className="header_Left">
                    <h3>Welcome back!</h3>
                </div>
                <div className="header_right">
                    {/*<img*/}
                    {/*    src={require("../images/profile.jpg")}*/}
                    {/*    alt="user image"*/}
                    {/*/>*/}
                    {/*JOhn Doe*/}

                </div>
                <button
                    className="logout_button"
                    type="submit"
                    value={"Logout"}
                    onClick={() => {
                        handlelogout(dispatch)
                    }
                    }>Logout
                </button>

            </div>
        </header>
    )
}

export default Header
