import React from "react"

import Table from "../../components/Table"

const Users = () => {
    return (
        <div className="table_box_content">
            <Table />
        </div>
    )
}

export default Users
