import React, {useEffect, useState} from "react"
import ReactPaginate from "react-paginate"
import {HiChevronLeft, HiChevronRight} from "react-icons/hi"

import ProductList from "../../components/Products"

const Products = ({ data, count, handlePageChange, pages }) => {
    const [pageNumber, setPageNumber] = useState("")
    useEffect(() => {
        handlePageChange(pageNumber)
    }, [pageNumber])

    return (
        <>
            {data && data.length > 0 && (
                <div className="product_list_wrapper">
                    <ProductList itemClass="4" items={data} />
                    {count && count > 9 && (
                        <div className="load_more_wrapper text-center">
                            <ReactPaginate
                                previousLabel={<HiChevronLeft />}
                                nextLabel={<HiChevronRight />}
                                pageCount={pages}
                                onPageChange={(pagenumber) =>
                                    setPageNumber(pagenumber)
                                }
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default Products
