import React, { useState, useEffect } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"

import { getAllBanners } from "../../store/actions/banner"
import { getAllAbout } from "../../store/actions/about"
import {
    getAllCategory,
    getAllProducts,
    getProductByCategory,
} from "../../store/actions/product"
import { getAllTestimonials } from "../../store/actions/testimonial"
import { getAllClients } from "../../store/actions/client"
import Banner from "./Banner"
import HomeAbout from "./About"
import FeaturedProducts from "./Featured"
import Testimonials from "../../components/testimonials"
import NewProducts from "./NewProducts"
import Category from "./Category"
import Clients from "../../components/clients/index"

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [isLoading, setIsLoading] = useState(false)
    const [catId, setCatId] = useState("")

    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllBanners())
        dispatch(getAllAbout())
        dispatch(getAllCategory())
        dispatch(getAllProducts())
        dispatch(getAllTestimonials())
        dispatch(getAllClients())
        dispatch(getProductByCategory(catId))
        setIsLoading(false)
    }, [dispatch, catId])
    const {
        publishedBanners,
        homeAbout,
        featuredProducts,
        publishedCategory,
        publishedproducts,
        newArrival,
        publishedtestimonials,
        filterFeaturedProdoucts,
        publishedClients,
    } = useSelector(
        (state) => ({
            publishedproducts: state.product.publishedproducts,
            publishedBanners: state.banner.publishedBanners,
            homeAbout: state.about.homeAbout,
            publishedCategory: state.product.publishedCategory,
            featuredProducts: state.product.featuredProducts,
            publishedtestimonials: state.testimonial.publishedtestimonials,
            publishedClients: state.client.publishedClients,
            newArrival: state.product.newArrival,
            filterFeaturedProdoucts: state.product.filterFeaturedProdoucts,
        }),
        shallowEqual
    )
    const handleCategoryId = (id) => {
        setCatId(id)
    }
    if (isLoading) return <p>Loading...</p>
    return (
        <>
            <Banner data={publishedBanners} />
            <HomeAbout data={homeAbout} />
            <FeaturedProducts
                category={publishedCategory}
                product={featuredProducts}
                handleCategoryId={(id) => handleCategoryId(id)}
                filteredProducts={filterFeaturedProdoucts}
            />
            <Category data={publishedCategory} />
            <NewProducts data={newArrival} />
            <Testimonials
                className="home_testinomials"
                data={publishedtestimonials}
            />
            <Clients className="bg-gray" data={publishedClients} />
        </>
    )
}

export default Home
