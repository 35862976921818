import React, { useEffect } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"

import { getSetting } from "../store/actions/setting"
import { getAllProducts } from "../store/actions/product"

import TopHeader from "../components/TopHeader"
import Header from "../components/Header"
import Footer from "../components/Footer"
import ScrollToTop from "../components/ScrollToTop"
import CompanyFooter from "../components/CompanyFooter"
import Messenger from "../components/Messenger"

const Public = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSetting())
        dispatch(getAllProducts())
    }, [dispatch])
    const { setting, publishedproducts } = useSelector(
        (state) => ({
            setting: state.setting.setting,
            publishedproducts: state.product.publishedproducts,
        }),
        shallowEqual
    )
    return (
        <div className="page-wrapper">
            <TopHeader data={setting} />
            <Header data={setting} products={publishedproducts} />
            {props.children}
            <Footer data={setting} products={publishedproducts} />
            <ScrollToTop />
            <CompanyFooter />
            <Messenger />
        </div>
    )
}

export default Public
