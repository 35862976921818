import React, { useState, useEffect } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import ReactPaginate from "react-paginate"

import { getAllServices, deleteService } from "../../../store/actions/service"
import Table from "../../components/Table"
import Search from "../../components/Search"

const Services = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllServices())
        setIsLoading(false)
    }, [dispatch])
    const { services } = useSelector(
        (state) => ({
            services: state.service.services,
        }),
        shallowEqual
    )
    if (isLoading) return <p>Loading...</p>
    const handleSearch = (searchTerm) => {
        dispatch(getAllServices(searchTerm))
    }
    return (
        <>
            <div className="table_box_content">
                <div className="table_header">
                    <h3>Services List</h3>
                    <Search
                        handleSearch={(searchTerm) => handleSearch(searchTerm)}
                    />
                </div>
                <Table
                    data={services}
                    deleteItem={(id) => dispatch(deleteService(id))}
                    linkTo="services"
                />
                <div className="table_footer">
                    <ReactPaginate />
                </div>
            </div>
        </>
    )
}

export default Services
