import React, { useState } from "react"

import { NavLink, Link } from "react-router-dom"
import { FaPaperPlane } from "react-icons/fa"

import MainLogo from "../assets/images/logo.png"
import Popup from "./Popup"

const Header = ({ data, products }) => {
    const [showDialog, setShowDialog] = useState(false)
    const handlePopup = () => {
        setShowDialog(true)
    }
    const handleCancel = () => {
        setShowDialog(false)
    }
    const [showMenu, setShowMenu] = useState(false)
    const handleMenuBar = () => {
        setShowMenu(!showMenu)
    }

    return (
        <>
            <nav className="navbar navbar-bg navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <Link className="navbar-brand text-uppercase" to="/">
                        <img src={MainLogo} alt="logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarsExample04"
                        aria-controls="navbarsExample04"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={handleMenuBar}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className={`${
                            showMenu ? "showMenu" : ""
                        } collapse navbar-collapse`}
                        id="navbarsExample04"
                    >
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item  active">
                                <NavLink
                                    className="nav-link text-uppercase"
                                    to="/"
                                    exact={true}
                                    onClick={handleMenuBar}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item  active">
                                <NavLink
                                    className="nav-link text-uppercase"
                                    to="/about-us"
                                    exact={true}
                                    onClick={handleMenuBar}
                                >
                                    About Us
                                </NavLink>
                            </li>
                            <li className="nav-item  active">
                                <NavLink
                                    className="nav-link text-uppercase"
                                    to="/products"
                                    exact={true}
                                    onClick={handleMenuBar}
                                >
                                    Products
                                </NavLink>
                            </li>
                            <li className="nav-item  active">
                                <NavLink
                                    className="nav-link text-uppercase"
                                    to="/services"
                                    exact={true}
                                    onClick={handleMenuBar}
                                >
                                    Services
                                </NavLink>
                            </li>
                            <li className="nav-item  active">
                                <NavLink
                                    className="nav-link text-uppercase"
                                    to="/contact-us"
                                    exact={true}
                                    onClick={handleMenuBar}
                                >
                                    Contact Us
                                </NavLink>
                            </li>
                            <li className="nav-item  active">
                                <button
                                    className="nav-link enquiry_btn text-uppercase"
                                    onClick={handlePopup}
                                >
                                    enquiry
                                    <FaPaperPlane />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Popup
                initState={showDialog}
                handleCancel={handleCancel}
                products={products}
            />
        </>
    )
}

export default Header
