import { combineReducers } from "redux"

import banner from "./banner"
import about from "./about"
import client from "./clients"
import product from "./product"
import testimonial from "./testimonial"
import contact from "./contact"
import service from "./service"
import faq from "./faq"
import enquiry from "./enquiry"
import setting from "./setting"
import auth from "./auth";

export default combineReducers({
    banner,
    about,
    client,
    product,
    testimonial,
    service,
    faq,
    contact,
    setting,
    enquiry,
    auth
})
