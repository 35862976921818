import React from "react"
import { useDispatch } from "react-redux"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

import { addContact } from "../../store/actions/contact"
import TextError from "../../admin/components/TextError"
import FormikControl from "../../admin/components/FormikControl"

const ContactForm = () => {
    let initialValues = {
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        phoneNumber: Yup.string().min(10).required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        subject: Yup.string().required("Required"),
        message: Yup.string().required("Required"),
    })

    const dispatch = useDispatch()
    const onSubmit = (values, { setSubmitting, resetForm }) => {
        dispatch(addContact(values)).then(() => {
            resetForm({})
            setSubmitting(false)
        })
    }
    return (
        <div className="contact_form box-shadow">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form>
                        <div className="form_inner_content">
                            <div className="row">
                                <div className="col-md-3">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Title"
                                        name="name"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <FormikControl
                                        control="input"
                                        type="email"
                                        label="Email"
                                        name="email"
                                    />
                                </div>

                                <div className="col-md-3">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="Phone Number"
                                        name="phoneNumber"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="subject"
                                        name="subject"
                                    />
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Message</label>
                                        <Field
                                            as="textarea"
                                            id="message"
                                            name="message"
                                            className="form-control"
                                        />
                                        <ErrorMessage
                                            name="message"
                                            component={TextError}
                                        />
                                    </div>
                                    <button className="view_all" type="submit">
                                        {formik.isSubmitting
                                            ? "Loading"
                                            : "Send message"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {/* <form>
                <div className="row">
                    <div className="col-md-3">
                        <Input label="Name" placeholder="Enter your name" />
                    </div>
                    <div className="col-md-3">
                        <Input
                            type="email"
                            label="Email"
                            placeholder="Enter your email"
                        />
                    </div>
                    <div className="col-md-3">
                        <Input
                            type="number"
                            label="Phone No"
                            placeholder="Enter your Phone No"
                        />
                    </div>
                    <div className="col-md-3">
                        <Input label="Subject" placeholder="Enter subject" />
                    </div>
                    <div className="col-md-12">
                        <Textarea
                            label="Message"
                            placeholder="Say something nice..."
                        />
                        <Button
                            type="button"
                            label="Send message"
                            className="view_all"
                        />
                    </div>
                </div>
            </form> */}
        </div>
    )
}

export default ContactForm
