import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Formik, Form } from "formik"
import { toast } from "react-toastify"
import * as Yup from "yup"

import {
    addAbout,
    getOneAbout,
    updateAbout,
    clearFields,
} from "../../../store/actions/about"
import FormikControl from "../../components/FormikControl"

const TestimonialForm = () => {
    const radioOptions = [
        { key: "Yes", value: "yes" },
        { key: "No", value: "no" },
    ]
    const { slug } = useParams()
    const dispatch = useDispatch()
    const isAddMode = !slug
    useEffect(() => {
        if (slug) {
            dispatch(getOneAbout(slug))
            toast.success("About read successfull")
        } else {
            dispatch(clearFields((initialValues = {})))
        }
    }, [dispatch, slug])
    const { singleAbout } = useSelector(
        (state) => ({
            singleAbout: state.about.singleAbout,
        }),
        shallowEqual
    )
    let initialValues = {
        name: singleAbout.name ? singleAbout.name : "",
        description: singleAbout.description ? singleAbout.description : "",
        isPublish:
            singleAbout.isPublish === true
                ? "yes"
                : singleAbout.isPublish === false
                ? "no"
                : "",
        alt: singleAbout.image ? singleAbout.image.alt : "",
        caption: singleAbout.image ? singleAbout.image.caption : "",
        url: null,
        isHomeAbout: singleAbout.isHomeAbout ? singleAbout.isHomeAbout : "",
        display_order: singleAbout.display_order
            ? singleAbout.display_order
            : "",
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        url: Yup.mixed().required("Required"),
        isPublish: Yup.string().required("Required"),
    })
    const onSubmit = (values, { setSubmitting }) => {
        let {
            name,
            description,
            isPublish,
            alt,
            caption,
            url,
            isHomeAbout,
            display_order,
        } = values
        let image = {}
        image.alt = alt
        image.caption = caption
        const dataToSend = new FormData()
        dataToSend.append("name", name)
        dataToSend.append("display_order", display_order)
        dataToSend.append("description", description)
        dataToSend.append("image[alt]", alt)
        dataToSend.append("image[caption]", caption)
        dataToSend.append("isPublish", isPublish)
        dataToSend.append("isHomeAbout", isHomeAbout)
        dataToSend.append("image", url)
        if (isAddMode) {
            dispatch(addAbout(dataToSend))
            setSubmitting(false)
        } else {
            dispatch(updateAbout(slug, dataToSend))
            setSubmitting(false)
        }
    }
    return (
        <div className="form_wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {
                    return (
                        <Form>
                            <h1>{isAddMode ? " About Form" : "Edit About"}</h1>
                            <div className="form_inner_content">
                                <div className="row">
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label="Title"
                                            name="name"
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <FormikControl
                                            control="input"
                                            type="number"
                                            label="Display Order"
                                            name="display_order"
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <FormikControl
                                            control="radio"
                                            label="Publish"
                                            name="isPublish"
                                            options={radioOptions}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="radio"
                                            label="Is Home About"
                                            name="isHomeAbout"
                                            options={radioOptions}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="image"
                                            label="About Image"
                                            name="url"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label="About Image Alt"
                                            name="alt"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormikControl
                                            control="input"
                                            type="text"
                                            label="About Image caption"
                                            name="caption"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <FormikControl
                                            control="textarea"
                                            label="Description"
                                            name="description"
                                            required
                                        />
                                        <button
                                            className="submit_btn"
                                            type="submit"
                                        >
                                            {formik.isSubmitting
                                                ? "loading"
                                                : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default TestimonialForm
