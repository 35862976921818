import Axios from "axios"

const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null

const instance = Axios.create({
    baseURL: "https://api.kitchenmartnepal.com/api/v1",
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
})

export default instance
