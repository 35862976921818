import React from "react"
import ReactQuill from "react-quill"
import { Field, ErrorMessage } from "formik"
import "react-quill/dist/quill.snow.css"

import TextError from "./TextError"

function Textarea(props) {
    const { label, name, required } = props

    return (
        <div className="form-group">
            <label htmlFor={name}>
                {label}
                {required && <span style={{ marginLeft: 3 }}>*</span>}
            </label>
            <Field name={name}>
                {({ field }) => (
                    <ReactQuill
                        value={field.value}
                        onChange={field.onChange(field.name)}
                        modules={Textarea.modules}
                        formats={Textarea.formats}
                    />
                )}
            </Field>
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}
Textarea.modules = {
    toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
        ["code-block"],
    ],
}
Textarea.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "code-block",
]
export default Textarea
