import React, {useEffect, useState} from "react"
import {shallowEqual, useDispatch, useSelector} from "react-redux"
import ReactPaginate from "react-paginate"
import {toast} from "react-toastify"

import {deleteTestimonial, getAllTestimonials,} from "../../../store/actions/testimonial"
import Search from "../../components/Search"
import TestimonialTable from "./TestimonialTable";

const Testimonial = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAllTestimonials())
        toast.success("Testimonial read successfull")
        setIsLoading(false)
    }, [dispatch])
    const { testimonials } = useSelector(
        (state) => ({
            testimonials: state.testimonial.testimonials,
        }),
        shallowEqual
    )
    const handleSearch = (searchTerm) => {
        dispatch(getAllTestimonials(searchTerm))
    }
    if (isLoading) {
        return <p>Loading....</p>
    }
    return (
        <>
            <div className="table_box_content">
                <div className="table_header">
                    <h3>testimonials List</h3>
                    <Search
                        handleSearch={(searchTerm) => handleSearch(searchTerm)}
                    />
                </div>
                {/*<Table*/}
                {/*    data={testimonials}*/}
                {/*    deleteItem={(id) => dispatch(deleteTestimonial(id))}*/}
                {/*    linkTo="testimonials"*/}
                {/*/>*/}
                <TestimonialTable
                    data={testimonials}
                    deleteItem={(id) => dispatch(deleteTestimonial(id))}
                    linkTo="testimonials"
                />
                <div className="table_footer">
                    <ReactPaginate />
                </div>
            </div>
        </>
    )
}

export default Testimonial
