import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Formik, Form } from "formik"
import { toast } from "react-toastify"
import * as Yup from "yup"

import {
    addFaq,
    getSingleFaq,
    updateFaq,
    clearFields,
} from "../../../store/actions/faq"
import FormikControl from "../../components/FormikControl"

const TestimonialForm = () => {
    const radioOptions = [
        { key: "Yes", value: "yes" },
        { key: "No", value: "no" },
    ]

    const dispatch = useDispatch()
    const { slug } = useParams()
    const isAddMode = !slug
    useEffect(() => {
        if (slug) {
            dispatch(getSingleFaq(slug))
            toast.success("Faq read successfull")
        } else {
            dispatch(clearFields((initialValues = {})))
        }
    }, [dispatch, slug])
    const { singleFaq } = useSelector(
        (state) => ({
            singleFaq: state.faq.singleFaq,
        }),
        shallowEqual
    )

    let initialValues = {
        name: singleFaq.name ? singleFaq.name : "",
        description: singleFaq.description ? singleFaq.description : "",
        isPublish:
            singleFaq.isPublish === true
                ? "yes"
                : singleFaq.isPublish === false
                ? "no"
                : "",
        display_order: singleFaq.display_order ? singleFaq.display_order : "",
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        isPublish: Yup.string().required("Required"),
        display_order: Yup.string().required("Required"),
    })
    const onSubmit = (values) => {
        if (isAddMode) {
            dispatch(addFaq(values))
        } else {
            dispatch(dispatch(updateFaq(slug, values)))
        }
    }
    return (
        <div className="form_wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form>
                        <h1>{isAddMode ? " FAQ Form" : "Edit Faq"}</h1>
                        <div className="form_inner_content">
                            <div className="row">
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Title"
                                        name="name"
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="Display Order"
                                        name="display_order"
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <FormikControl
                                        control="radio"
                                        label="Publish"
                                        name="isPublish"
                                        options={radioOptions}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <FormikControl
                                        control="textarea"
                                        label="Description"
                                        name="description"
                                        required
                                    />
                                    <button
                                        className="submit_btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default TestimonialForm
