import React from "react"
import Counter from "react-number-counter"

const List = ({ count, title }) => {
    return (
        <div className="list_item">
            <div className="item_left">
                <Counter start={0} end={count ? count : 500} delay={10} />
            </div>
            <div className="item_right">{title && title}</div>
        </div>
    )
}

export default List
