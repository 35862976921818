import React, { useState } from "react"

import Logo from "../images/logo.png"
import { Link } from "react-router-dom"
import { items } from "../routes"

const Sidebar = () => {
    const [showDropdown, setDropdown] = useState("")
    const toggleMenuItems = (name) => {
        setDropdown(name)
    }
    return (
        <div className="admin_sidebar">
            <div className="sidebar_logo">
                <img src={Logo} alt="" />
            </div>
            <div className="sidebar_menu">
                <div className="sidebar-links">
                    <ul className="sidebar-nav">
                        {items.map(
                            ({ link, name, items: subItems, ...rest }) => (
                                <li
                                    key={name}
                                    className={
                                        name === showDropdown
                                            ? "nav-item  pos-relative active"
                                            : "nav-item  pos-relative"
                                    }
                                    {...rest}
                                    onClick={() => toggleMenuItems(name)}
                                >
                                    {link ? (
                                        <Link to={link} className="nav-link">
                                            {name}
                                        </Link>
                                    ) : (
                                        <button className="nav-item pos-relative">
                                            {name}
                                            <span
                                                className={
                                                    name === showDropdown
                                                        ? "fa fa-minus"
                                                        : "fa fa-plus"
                                                }
                                            />
                                        </button>
                                    )}
                                    {Array.isArray(subItems) ? (
                                        <ul
                                            className={
                                                name === showDropdown
                                                    ? "dropdown show"
                                                    : "dropdown"
                                            }
                                        >
                                            {subItems.map((subItem) => (
                                                <li key={subItem.name}>
                                                    <Link to={subItem.link}>
                                                        {subItem.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null}
                                </li>
                            )
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
