import {
    ADD_CLIENT,
    GET_ALL_CLIENTS,
    GET_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    CLEAR_FIELDS,
} from "../constants/client"

const initialState = {
    clients: [],
    publishedClients: [],
    client: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_CLIENT:
            return {
                ...state,
                clients: state.clients.concat(action.payload.data),
            }

        case GET_ALL_CLIENTS: {
            return {
                ...state,
                clients: action.payload.data,
                publishedClients: action.payload.data.filter((item) => {
                    return item.isPublish === true
                }),
            }
        }
        case GET_CLIENT: {
            return {
                client: action.payload.data,
            }
        }
        case UPDATE_CLIENT: {
            return {
                ...state,
                client: action.payload.data,
            }
        }
        case DELETE_CLIENT: {
            return {
                ...state,
                clients: state.clients.filter(
                    (item) => item.slug !== action.payload
                ),
            }
        }
        case CLEAR_FIELDS: {
            return {
                client: action.payload,
            }
        }
        default:
            return state
    }
}
