import {LOGIN, LOGOUT} from "../constants/auth";

const initialState = {
    isAuthenticated: true,
    token: null,
    loading: false,
}

export default function (state = initialState, action) {
    let data = action.payload

    switch (action.type) {
        case LOGIN: {
            console.log("inside reducer")

            let { token, user } = data
            localStorage.setItem("token", token)
            return {
                ...state,
                isAuthenticated: localStorage.getItem("token") ? true : false,
                token: localStorage.getItem("token"),
                user,
                loading: false,
            }
        }
        case LOGOUT: {
            if (localStorage.getItem("token")) {
                localStorage.removeItem("token")
            }
            return {
                isAuthenticated: false,
                token: null,
                user: null,
                loading: false,
            }
        }
        default:
            return {
                ...state,
                isAuthenticated: localStorage.getItem("token") ? true : false,
                token: localStorage.getItem("token"),
            }
    }
}
