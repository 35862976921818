import React from "react";

import { AiTwotoneHeart } from "react-icons/ai";

const CompanyFooter = () => {
  return (
    <div className="company_footer">
      <div className="container">
        <p style={{textAlign:'center'}}>
          Design and developed with <AiTwotoneHeart /> by Mervix technologies
        </p>
      </div>
    </div>
  );
};

export default CompanyFooter;
