import React from "react"
import Select from "react-select"
import { Field, ErrorMessage } from "formik"
import TextError from "./TextError"

const ReactSelect = (props) => {
    const { label, name, options, isMulti, ...rest } = props
    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <Field name={name}>
                {({ form, field }) => {
                    const { setFieldValue } = form
                    const { value } = field
                    return (
                        <Select
                            id={name}
                            isMulti={isMulti && isMulti}
                            {...field}
                            {...rest}
                            selected={value}
                            options={options}
                            onChange={(val) => setFieldValue(name, val)}
                        />
                    )
                }}
            </Field>
            <ErrorMessage component={TextError} name={name} />
        </div>
    )
}

export default ReactSelect
