import Api from "../API/axios"
import { toast } from "react-toastify"
import {
    ADD_BANNER,
    GET_ALL_BANNERS,
    UPDATE_BANNER,
    DELETE_BANNER,
    GET_BANNER,
    CLEAR_FIELDS,
} from "../constants/banner"

export const addBanner = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post("/banners", data)
            dispatch({ type: ADD_BANNER, payload: res })
            dispatch(toast.success("Banner added successfully"))
            return res.data
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const getAllBanners = (searchTerm) => {
    const query = searchTerm ? searchTerm : ""
    return async (dispatch) => {
        try {
            const res = await Api.get(`/banners?search=${query}`)
            dispatch({ type: GET_ALL_BANNERS, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const getBanner = (id) => {
    return async (dispatch) => {
        try {
            const res = await Api.get(`/banners/${id}`)
            dispatch({ type: GET_BANNER, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}

export const updateBanner = (id, data) => {
    return async (dispatch) => {
        try {
            const res = await Api.put(`/banners/${id}`, data)
            dispatch({ type: UPDATE_BANNER, payload: res.data })
            toast.success("Banner updated successfully")
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
}

export const deleteBanner = (id) => {
    return async (dispatch) => {
        try {
            await Api.delete(`/banners/${id}`)
            dispatch({ type: DELETE_BANNER, payload: id })
            toast.success("Banner deleted successfully")
        } catch (err) {
            toast.error("Error deleting banner")
        }
    }
}

export const clearFields = (data) => {
    return {
        type: CLEAR_FIELDS,
        payload: data,
    }
}
