import React from "react"

import Title from "../Title"
import TestimonialItem from "./Testimonial"

const index = (props) => {
    return (
        <>
            {props.data && props.data.length > 0 && (
                <section
                    className={`section testimonial-wrapper  ${
                        props.className ? props.className : ""
                    }`}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-8">
                                <Title
                                    title="our testimonials"
                                    className="text-center"
                                    bgTitle="our testimonials"
                                />
                                <TestimonialItem data={props.data} />
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export default index
