import React from "react"
import renderHTML from "react-render-html"

import Title from "../../components/Title"

const About = ({ data }) => {
    return (
        <>
            {data && data.length > 0 && (
                <div className="section about-section-wrapper pos-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <Title
                                    title="WHO WE ARE"
                                    bgTitle="Who we are"
                                />
                                {renderHTML(data[0].description)}
                            </div>
                            <div className="col-md-6">
                                <div className="about-img">
                                    <figure>
                                        <img src={data[0]?.image?.url} alt="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default About
