import React from "react"
import Slider from "react-slick"

import ProductItem from "./ProductItem"

const Products = ({
    slider,
    dots,
    autoPlay,
    arrows,
    itemClass,
    items,
    newProducts,
    relatedproducts,
}) => {
    var settings = {
        dots: dots ? true : false,
        infinite: items && items.length > 5 ? true : false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: autoPlay ? true : false,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    }
    if (slider) {
        return (
            <div className="product_slider">
                <Slider {...settings}>
                    {items &&
                        items.map((item, index) => {
                            return (
                                <ProductItem
                                    key={item._id}
                                    image={item.image}
                                    name={item.name}
                                    slug={item.slug}
                                    relatedproducts={relatedproducts}
                                />
                            )
                        })}
                </Slider>
            </div>
        )
    } else {
        return (
            <div className="row">
                {newProducts ? (
                    <>
                        {items &&
                            items
                                .filter((item, idx) => idx < 8)
                                .map((item, index) => {
                                    return (
                                        <div
                                            className={`col-md-${
                                                itemClass ? itemClass : 4
                                            }`}
                                            key={index}
                                        >
                                            <ProductItem
                                                image={item.image}
                                                name={item.name}
                                                slug={item.slug}
                                            />
                                        </div>
                                    )
                                })}
                    </>
                ) : (
                    <>
                        {items &&
                            items.map((item, index) => {
                                return (
                                    <div
                                        className={`col-md-${
                                            itemClass ? itemClass : 4
                                        }`}
                                        key={index}
                                    >
                                        <ProductItem
                                            image={item.image}
                                            name={item.name}
                                            slug={item.slug}
                                        />
                                    </div>
                                )
                            })}
                    </>
                )}
            </div>
        )
    }
}

export default Products
