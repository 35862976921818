import Api from "../API/axios"
import { ADD_CONTACT, GET_ALL_CONTACTS } from "../constants/contact"
import { toast } from "react-toastify"

export const addContact = (data) => {
    return async (dispatch) => {
        try {
            const res = await Api.post("/contacts", data)
            if (res?.data?.message === "success") {
                toast.success("Message send successfully")
            }
            dispatch({ type: ADD_CONTACT, payload: res.data })
            return res
        } catch (err) {
            console.log(err)
        }
    }
}

export const getAllContacts = () => {
    return async (dispatch) => {
        try {
            const res = await Api.get("/contacts")
            dispatch({ type: GET_ALL_CONTACTS, payload: res.data })
        } catch (err) {
            console.log(err)
        }
    }
}
