import React from "react"
import Slider from "react-slick"
import Magnifier from "react-magnifier"

const ProductSlider = ({ data }) => {
    const pagination = []
    if (data && data.productImages && data.productImages.length > 0) {
        data.productImages.map((item) => {
            pagination.push(item)
        })
    }
    const settings = {
        customPaging: function (i) {
            return (
                <div className="dots-wrapper">
                    {pagination[i] && (
                        <img
                            src={pagination[i]}
                            alt={`product-images-${pagination[i]}`}
                            key={`product-images-${pagination[i]}`}
                        />
                    )}
                </div>
            )
        },
        dots: true,
        dotsClass: "slick-dots product-img-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    return (
        <div className="product_slider_wrapper">
            <Slider {...settings}>
                {data &&
                data.productImages &&
                data &&
                data.productImages.length > 0 ? (
                    data.productImages.map((item, index) => {
                        return (
                            <div key={index._id}>
                                <div className="detail-product-img">
                                    <Magnifier src={item} />
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div>
                        <div className="detail-product-img">
                            <Magnifier src={data?.image?.url} />
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    )
}

export default ProductSlider
