import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { Formik, Form } from "formik"
import { toast } from "react-toastify"
import * as Yup from "yup"

import {
    addService,
    getService,
    updateService,
    clearFields,
} from "../../../store/actions/service"
import FormikControl from "../../components/FormikControl"

const ServiceForm = () => {
    const radioOptions = [
        { key: "Yes", value: "yes" },
        { key: "No", value: "no" },
    ]

    const dispatch = useDispatch()
    const { slug } = useParams()
    const isAddMode = !slug
    useEffect(() => {
        if (slug) {
            dispatch(getService(slug))
            toast.success("Service read successfull")
        } else {
            dispatch(clearFields((initialValues = {})))
        }
    }, [dispatch, slug])
    const { service } = useSelector(
        (state) => ({
            service: state.service.service,
        }),
        shallowEqual
    )

    let initialValues = {
        name: service.name ? service.name : "",
        email: service.email ? service.email : "",
        display_order: service.display_order ? service.display_order : "",
        alt: service.image ? service.image.alt : "",
        caption: service.image ? service.image.caption : "",
        url: null,
        isPublish:
            service.isPublish === true
                ? "yes"
                : service.isPublish === false
                ? "no"
                : "",
        description: service.description ? service.description : "",
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        url: Yup.mixed().required("Required"),
        description: Yup.string().required("Required"),
        isPublish: Yup.string().required("Required"),
    })
    const onSubmit = (values) => {
        let {
            name,
            email,
            companyName,
            phoneNumber,
            position,
            companyAddress,
            display_order,
            alt,
            caption,
            url,
            isPublish,
            description,
        } = values
        let image = {}
        image.alt = alt
        image.caption = caption
        const dataToSend = new FormData()
        dataToSend.append("name", name)
        dataToSend.append("display_order", display_order)
        dataToSend.append("description", description)
        dataToSend.append("image[alt]", alt)
        dataToSend.append("image[caption]", caption)
        dataToSend.append("isPublish", isPublish)
        dataToSend.append("image", url)
        if (isAddMode) {
            dispatch(addService(dataToSend))
        } else {
            dispatch(updateService(slug, dataToSend))
        }
    }
    return (
        <div className="form_wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form>
                        <h1>{isAddMode ? " Service Form" : "Edit Service"}</h1>
                        <div className="form_inner_content">
                            <div className="row">
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Service Title"
                                        name="name"
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="input"
                                        type="number"
                                        label="Display Order"
                                        name="display_order"
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="image"
                                        label="Image"
                                        name="url"
                                        required
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormikControl
                                        control="radio"
                                        label="Is Publish"
                                        name="isPublish"
                                        required
                                        options={radioOptions}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <FormikControl
                                        control="textarea"
                                        label="Description"
                                        name="description"
                                        required
                                    />
                                    <button
                                        className="submit_btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ServiceForm
